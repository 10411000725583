/**
 * Login Helper class
 */
import analytics from './analytics';
import { googleLogout } from '@react-oauth/google';
import properties from './properties';
import ServerApi from './ServerApi';
import { AxiosError } from 'axios';

const FIVE_MIN = 60000 * 5;

export const BROWSER_UUID = 'joyrun-orders';
export const ERROR_SESSION_EXPIRED:string = 'DAT-10432';
export type RegisterType = {
  restaurant_id_string:string,
  restaurant_otp:string
}

type AuthOptions = {
  register:RegisterType|undefined,
  jwt:string
}

interface LoginType {
  network:string
  auth_jwt?:string,
  app_version:string
  restaurant_id_string?:string,
  restaurant_otp?:string,
}


class Security {

  // Authentication methods
  public AUTH_GOOGLE:string = 'google';
  public NOT_REST:string = 'not_a_restaurant';

  private deviceId:string = BROWSER_UUID;
  private inProgress:boolean = false;


  authenticate(user:any, deviceId:string, options:AuthOptions):Promise<any> {
    return new Promise((resolve, reject) => {
      this.deviceId = deviceId;

      // Already authenticated
      const sessionToken = properties.getItem(properties.PROPERTY_SESSION_TOKEN);
      if (user.token && sessionToken) {
        ServerApi.setHeader(ServerApi.HEADER_SESSION_TOKEN, sessionToken);
        return resolve(user);
      }

      // Remove old headers
      properties.removeItem(properties.PROPERTY_OAUTH_TOKEN);
      properties.removeItem(properties.PROPERTY_OAUTH_TOKEN_EXPIRY);
      
      const data:LoginType = {
        network: this.AUTH_GOOGLE,
        app_version: "2.4.54"
      };
      if (options.jwt) {
        data.auth_jwt = options.jwt;
      }
      if (options.register) {
        data.restaurant_id_string = options.register.restaurant_id_string;
        data.restaurant_otp = options.register.restaurant_otp;
      }
        
      this.registerUser(data)
      .then(resolve)
      .catch((error:AxiosError) => {
        reject({ error: error.response && error.response.data ? error.response.data : 'Unknown error' });
      });
    });
  }


  logoutUser(user:any, callback:Function, reason:string, expired?:boolean, noReload?:boolean):void {
    analytics.track(analytics.TRACK_USER_LOGOUT_BEGIN, {
      user: user.id,
      reason: reason || 'unknown',
    });

    this.clearLoginCookie();

    this.inProgress = true;
    try {
      googleLogout();
    } catch (err) {
      console.error('Error signing out', err);  
    }
    this.logoutSuccessful(callback, expired, noReload);
  }


  private logoutSuccessful(callback:Function, expired?:boolean, noReload?:boolean) {
    this.inProgress = false;

    // Clear window.localStorage, but keep the users restaurant ID if they were logged out due to session expire
    const restId = properties.getItem(properties.PROPERTY_RESTAURANT_ID);
    window.localStorage.clear();

    // If the session was expired, show a warning and reload so they go to login.
    // Don't call the callback since we don't want anything else to happen.
    if (expired) {
      properties.setItem(properties.PROPERTY_RESTAURANT_ID, restId || ''); // Restore this

      callback(true);
    }
    else {
      if (callback) {
        callback(false);
      }
      if (!noReload) {
        continueToReload();
      }
    }

    function continueToReload() {
      window.location.reload();
    }
  }


  private registerUser(userData:any):Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = {};

      // properties.setItem(properties.PROPERTY_OAUTH_TOKEN, token);
      ServerApi.setHeader(ServerApi.HEADER_DEVICE_ID, this.deviceId);
      ServerApi.clientApi.postQuietly('/v4/user/login', userData, headers)
      .then( loginResp => {
        loginResp = loginResp.data;

        // Restaurant Login
        if (loginResp.restaurant_id) {
          this.setLoginCookie(loginResp);
          setLoginValues(loginResp);
          resolve(loginResp);
        }
        
        // NOT ALLOWED
        else {
          reject({response: {data: this.NOT_REST }});
        }
      })
      .catch(reject);
    });
  }


  verifySession():boolean {
    return !this.sessionExpiringSoon();
  }


  private clearLoginCookie() {
    const expired = 'Thu, 01 Jan 1970 00:00:00 UTC';
    document.cookie = `JRCID=; expires=${expired};`;
    document.cookie = `JRSPID=; expires=${expired};`;
  }


  private setLoginCookie(loginData:any) {
    const SELLER_ID = loginData.dasher_user_id;
    const SESSION = loginData.session_token;
    const expiry = new Date(loginData.session_token_expiry).toUTCString();

    document.cookie = `JRCID=${SELLER_ID}; expires=${expiry};`;
    document.cookie = `JRSPID=${SESSION}; expires=${expiry};`;
  }


  private sessionExpiringSoon():boolean {
    const threshold = Date.now() + FIVE_MIN; // Log the user out gracefully if their session will expire in the next 5 min.
    const oathExpiry = new Date(properties.getItem(properties.PROPERTY_OAUTH_TOKEN_EXPIRY) || '').getTime();
    const sessionExpiry = new Date(properties.getItem(properties.PROPERTY_SESSION_TOKEN_EXPIRY) || '').getTime();
    return threshold > oathExpiry || threshold > sessionExpiry;
  }
}


function setLoginValues(loginData:any) {

  // If one restaurant owner or admin login (no owned restaurants but loginData.restaurant_id exists) then set restaurant_id in PROPERTY_RESTAURANT_ID
  const owned = loginData.owned_restaurants ? loginData.owned_restaurants : {};
  const ownedKeyLength = Object.keys(owned).length;
  if (ownedKeyLength === 1 || (ownedKeyLength === 0 && loginData.restaurant_id)) {
    properties.setItem(properties.PROPERTY_RESTAURANT_ID, loginData.owned_restaurants[0] || loginData.restaurant_id);
  } // Else, we set this in the restaurant picker

  // API headers
  // ServerApi.setHeader(ServerApi.HEADER_SESSION_TOKEN, loginData.session_token);

  // Auth data
  properties.setItem(properties.PROPERTY_OAUTH_TOKEN_EXPIRY, loginData.oauth_token_expiry);
  properties.setItem(properties.PROPERTY_SESSION_TOKEN, loginData.session_token);
  properties.setItem(properties.PROPERTY_SESSION_TOKEN_EXPIRY, loginData.session_token_expiry);
  
  // User data
  properties.setItem(properties.PROPERTY_USER, loginData);
  properties.setItem(properties.PROPERTY_OWNED_RESTAURANTS, owned);
}

export default new Security();