import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import ServerApi from '../../lib/ServerApi';
import * as Icon from "@livingdesign/icons";
import { RestaurantContext } from '../MerchantPortal/MerchantPortal';
import { useNavigate } from 'react-router-dom';
import { BrandContext } from '../../App/App';
import { DRIVER_LOAD_ERROR, LANDING_DRIVER_TITLE, LANDING_HEADER, formatPhoneNumber } from '../../lib/string';
import {
  Heading,
  Breadcrumb,
  BreadcrumbItem,
  Alert,
  Card,
  Body,
  DataTable,
  DataTableHead,
  DataTableRow,
  DataTableHeader,
  Button,
  DataTableBody,
  DataTableCell,
  DataTableCellActions,
  Spinner,
  useSnackbar,
} from "@walmart-web/livingdesign-components";
import Page from '../Page/Page';
import DriverModal from './DriverModal';
import RemoveDriverModal from './RemoveDriverModal';

import './DriverRoster.scss';

export type Driver = {
  first_name: string
  last_name: string
  phone_number: string
  driver_id: string
  store_id: string
  deleted: boolean
}


const DriverRoster: React.FunctionComponent<{}> = props => {
  const brand = useContext(BrandContext);
  const restaurant = useContext(RestaurantContext);
  const navigate = useNavigate();
  const {addSnack} = useSnackbar();
  
  const [loadingDrivers, setLoadingDrivers] = useState(true);
  const [drivers, setDrivers] = useState<Driver[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [driverToEdit, setDriverToEdit] = useState<Driver | null>(null);
  const [driverToDelete, setDriverToDelete] = useState<Driver | null>(null);
  const [errorMessage, setErrorMessage] = useState('');


  const loadDrivers = useCallback(() => {
    setDrivers([]);
    setLoadingDrivers(true);
    getDrivers(restaurant.restaurant_id)
    .then(drivers => {
      setDrivers(drivers);
    })
    .catch(err => {
      setErrorMessage(brand.getString(DRIVER_LOAD_ERROR));
    })
    .finally(() => {
      setLoadingDrivers(false);
    });
  }, [brand, restaurant.restaurant_id]);


  useEffect(() => {
    loadDrivers();
  }, [loadDrivers]);


  function closeModal() {
    setDriverToEdit(null);
    setShowModal(false);
  }


  function editDriver(driver:Driver) {
    setDriverToEdit(driver);
    setShowModal(true);
  }


  return (
    <Page className='DriverRoster' title={brand.getString(LANDING_DRIVER_TITLE)} onReturnClick={() => navigate(`/${restaurant.restaurant_id}`)}>
      <Breadcrumb UNSAFE_className="breadcrumb">
        <BreadcrumbItem href={getParentURL()}>
          {brand.getString(LANDING_HEADER)}
        </BreadcrumbItem>
        <BreadcrumbItem href={getParentURL() + "payout"} isCurrent>
          {brand.getString(LANDING_DRIVER_TITLE)}
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="content">
        <Heading as="h1" size="large" weight={700}>Your driver roster</Heading>
        { errorMessage && <Alert variant="error">{errorMessage}</Alert>}
        <Card size="large" UNSAFE_className='roster-card'>
          <div className="top">
            <div>
              <Heading as="h1" size="large">Drivers</Heading>
              <Body size='small'>When an order is ready for delivery, your drivers will receive a text message with all the information they'll need.</Body>
            </div>
            <Button leading={<Icon.Car />} onClick={() => setShowModal(true)}>
              Add driver
            </Button>
          </div>

          <DataTable>
            <DataTableHead>
              <DataTableRow>
                <DataTableHeader>Name</DataTableHeader>
                <DataTableHeader>Phone number</DataTableHeader>
                <DataTableHeader alignment='right'>Actions</DataTableHeader>
              </DataTableRow>
            </DataTableHead>
            <DataTableBody>
              { drivers.map(driver => (
                <DataTableRow key={ driver.driver_id }>
                  <DataTableCell>{driver.first_name} {driver.last_name}</DataTableCell>
                  <DataTableCell>{formatPhoneNumber(driver.phone_number)}</DataTableCell>
                  <DataTableCellActions>
                    <Button leading={<Icon.TrashCan />} variant="tertiary" onClick={() => setDriverToDelete(driver) }>
                      Remove
                    </Button>
                    <Button leading={<Icon.Pencil />} variant="tertiary" onClick={() => editDriver(driver) }>
                      Edit
                    </Button>
                  </DataTableCellActions>
                </DataTableRow>
              ))}
            </DataTableBody>
          </DataTable>

          { loadingDrivers && (
            <div className="loading-drivers">
              <Spinner />
            </div>
          )}
        </Card>
      </div>

      { showModal && (
        <DriverModal
          sellerId={ restaurant.restaurant_id } 
          driver={ driverToEdit || undefined}
          onClose={ closeModal }
          onSuccess={ edited => {
            closeModal();
            loadDrivers();
            addSnack({message: edited ? 'Driver updated.' : 'Driver added.'});
          }}
          />
      )}

      { driverToDelete && (
        <RemoveDriverModal
          sellerId={ restaurant.restaurant_id }
          driver={ driverToDelete }
          onClose={ () => setDriverToDelete(null) }
          onSuccess={ () => {
            loadDrivers();
            setDriverToDelete(null)
            addSnack({message: 'Driver removed.'});
          }}
          />
      )}
    </Page>
  )
}


function getParentURL() {
  const baseURL = window.location.href;
  return baseURL.slice(0, baseURL.lastIndexOf('/') + 1)
}


function getDrivers(sellerId:string):Promise<Driver[]> {
  return new Promise((resolve, reject) => {
    ServerApi.supportApi.get(`/v1/drivers/${sellerId}?deleted=false`)
    .then((response:AxiosResponse) => {
      if (response && response.status === 200 && response.data) {
        resolve(response.data);
      }
      else {
        reject();
      }
    })
    .catch(reject);
  });
}

export default DriverRoster