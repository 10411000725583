export default {
  PROPERTY_SESSION_TOKEN: 'PROPERTY_SESSION_TOKEN',
  PROPERTY_OAUTH_TOKEN: 'PROPERTY_OAUTH_TOKEN',
  PROPERTY_OAUTH_TOKEN_EXPIRY: 'PROPERTY_OAUTH_TOKEN_EXPIRY',
  PROPERTY_LOGIN_METHOD: 'PROPERTY_LOGIN_METHOD',
  PROPERTY_RESTAURANT_ID: 'PROPERTY_RESTAURANT_ID',
  PROPERTY_OWNED_RESTAURANTS: 'PROPERTY_OWNED_RESTAURANTS',
  PROPERTY_SESSION_TOKEN_EXPIRY: 'PROPERTY_SESSION_TOKEN_EXPIRY',
  PROPERTY_RESTAURANT_NAME: 'PROPERTY_RESTAURANT_NAME',
  PROPERTY_USER_TOKEN: 'PROPERTY_USER_TOKEN',

  PROPERTY_USER: 'ls.user',


  
  setItem: (key:string, val:any):void => {
    if (typeof val !== 'string') {
      val = JSON.stringify(val);
    }
    localStorage.setItem(key, val);
  },

  getItem: (key:string):any => {
    let val = localStorage.getItem(key);

    if (val) {
      try {
        val = JSON.parse(val);
      } catch (e) {}
      return val;
    }
    else {
      return null;
    }
  },

  removeItem: (key:string):void => {
    localStorage.removeItem(key);
  }
};