import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BrandContext, PORTRAIT_VIEW, parseError, AuthContext } from '../../App/App';
import analytics from '../../lib/analytics';
import security from '../../lib/security';
import LoginImage from '../common/LoginImage/LoginImage';
import { GoogleLogin } from '@react-oauth/google';
import LoginForm from './LoginForm/LoginForm';
import { Alert, Body, Heading } from '@walmart-web/livingdesign-components';
import { LOGIN_GENERAL_ERROR, LOGIN_TITLE, LOGIN_UNAUTHORIZED_ERROR } from '../../lib/string';

import './Login.scss';

export type LoginOptions = {
  jwt?:string
  hashId?:string
  otp?:string
}

type LoginErrorType = {
  title: string,
  message: string,
}

interface LoginProps {
}

// Mode constants
const MODE_CHECKING:string = 'checking';
const MODE_NEED_LOGIN:string = 'need-login';
const MODE_LOGIN_IN_PROGRESS:string = 'logging-in';
const MODE_LOGIN_SUCCEEDED:string = 'success';
const MODE_LOGIN_ERROR:string = 'login-error';


const Login:React.FunctionComponent<LoginProps> = props => {
  const brand = useContext(BrandContext);
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const [ mode, setMode ] = useState(MODE_NEED_LOGIN);
  const [ showError, setShowError ] = useState<LoginErrorType|null>(null);


  //
  // Navigate to root if the user is signed in
  //
  useEffect(() => {
    if (auth.user && auth.user.dasher_user_id) {
      if (security.verifySession()) {
        navigate('/');
      }
      else {
        navigate('/logout/expiring-soon');
      }
    }
  }, [auth.user]);


  function handleGoogleIdentityServiceResponse(response:{credential:string}) {
    if (response && response.credential) { 
      login({jwt:response.credential})
    } else {
      showLoginError('There was a problem getting your credentials.');
    }
  }


  function login(options:LoginOptions):void {
    setShowError(null);
    analytics.track(analytics.TRACK_LOGIN_CLICKED);
    if (mode === MODE_LOGIN_IN_PROGRESS) {
      return;
    }

    setMode(MODE_LOGIN_IN_PROGRESS);
    auth.login(options)
    .then(() => {
      setMode(MODE_LOGIN_SUCCEEDED);
    })
    .catch(err => {
      setMode(MODE_NEED_LOGIN);
      showLoginError(err);
    });
  }


  function showLoginError(error:any) {
    setMode(MODE_LOGIN_ERROR);

    if (error && error.error && error.error === security.NOT_REST) {
      setShowError({
        title: 'Not Authorized',
        message: brand.getString(LOGIN_UNAUTHORIZED_ERROR),
      });
      analytics.track(analytics.TRACK_LOGIN_ERROR, {
        error: 'NOT_RESTAURANT',
      });
    }
    else {
      setShowError({
        title: 'Login Problem',
        message: brand.getString(LOGIN_GENERAL_ERROR),
      });
      const err = error && error.error ? parseError(error.error).code : 'UNKNOWN';
      analytics.track(analytics.TRACK_LOGIN_ERROR, {
        error: err,
      });
    }
    window.localStorage.clear();
    setMode(MODE_NEED_LOGIN);
  }

  
  return (
    <LoginForm className="Login">
      { showError && 
        <Alert className="alert" variant="error">
          <Body>{ showError.message }</Body>
        </Alert>
      }

      <Heading as="h1" size="large" weight={700}>
        { brand.getString(LOGIN_TITLE) }
      </Heading>
      
      <LoginImage hidden={!PORTRAIT_VIEW} />

      { mode === MODE_NEED_LOGIN ? 
        <GoogleLogin 
          theme='outline'
          size='large'
          text='signin_with'
          shape='pill'
          logo_alignment='left'
          onSuccess={ (credentialResponse:any) => handleGoogleIdentityServiceResponse(credentialResponse) }
          onError={ () => showLoginError('no credentials returned') }/>
        :
        <div>{ renderMessages(mode) }</div>
      }
    </LoginForm>
  );
}


function renderMessages(mode:string):any {
  switch(mode) {
    case MODE_CHECKING:
      return <div className="status-message">Verifying account status...</div>;
    case MODE_LOGIN_IN_PROGRESS:
      return <div className="status-message">Verifying account status...</div>;
    case MODE_LOGIN_SUCCEEDED:
      return <div className="status-message">Getting things ready...</div>;
  }
}

export default Login;