import React, { useContext, useState, useEffect } from 'react';
import Axios, { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import ServerApi from '../../lib/ServerApi';
import { RestaurantContext } from '../MerchantPortal/MerchantPortal';
import InvoiceHistory from '../InvoiceHistory/InvoiceHistory';
import Page from '../Page/Page';
import {
  Heading,
  Button,
  Card,
  CardHeader,
  CardContent,
  Body,
  Spinner,
  Breadcrumb,
  BreadcrumbItem,
  Alert,
  Metric,
  IconButton,
  Callout,
} from "@walmart-web/livingdesign-components";
import { InfoCircle } from "@livingdesign/icons";
import { GENERAL_CONTACT_SUPPORT_ERROR, LANDING_HEADER } from '../../lib/string';
import { BrandContext } from '../../App/App';


import './InvoiceHome.scss';

const PAGE_TITLE: string = `Current Balance & Invoices`;

interface InvoiceHomeProps {
}

const InvoiceHome: React.FunctionComponent<InvoiceHomeProps> = props => {
  const brand = useContext(BrandContext);
  const restaurant = useContext(RestaurantContext);
  
  const [loadingInvoiceData, setLoadingInvoiceData] = useState(false);
  const [invoices, setInvoices] = useState<Array<any>>([]);
  const [currentBalance, setCurrentBalance] = useState<number | null>(null);
  const [currentBalanceUpdated, setCurrentBalanceUpdated] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isBalanceCalloutOpen, setIsBalanceCalloutOpen] = useState(false);
  const navigate = useNavigate()
  const balanceRef = React.useRef(null);

  const getParentURL = () => {
    const baseURL = window.location.href;
    return baseURL.slice(0, baseURL.lastIndexOf('/') + 1)
  }
  
  // Fetch invoice data.
  useEffect(() => {
    const fetchInvoicesData = () => {
      setLoadingInvoiceData(true);
      fetchInvoices(restaurant.restaurant_id)
      .then(({current_balance, current_balance_updated_at, invoices}) => {
              setInvoices(invoices || []);
              setCurrentBalance(current_balance);
              setCurrentBalanceUpdated(current_balance_updated_at);
            })
      .catch(() => {
        setErrorMessage(brand.getString(GENERAL_CONTACT_SUPPORT_ERROR));
        setLoadingInvoiceData(false);
      });
      setLoadingInvoiceData(false);
    }
    fetchInvoicesData();
  }, [restaurant.restaurant_id]);

  return (
    <Page className='InvoiceHome' title={PAGE_TITLE} onCloseClick={() => navigate(`/${restaurant.restaurant_id}`)}>
      <Breadcrumb className="breadcrumb">
        <BreadcrumbItem href={getParentURL()}>
          {brand.getString(LANDING_HEADER)}
        </BreadcrumbItem>
        <BreadcrumbItem href={getParentURL() + "Invoice"} isCurrent>
        {PAGE_TITLE}
        </BreadcrumbItem>
      </Breadcrumb>
      { errorMessage && <Alert variant="error">{errorMessage}</Alert>}
      { loadingInvoiceData ? <div className='spinner'><Spinner /></div> :
        <div>
          <div className="background-box">
            <Card size="small" className="invoice-home-card">
              <CardContent className="balance-card-content">
                <div className="balance-title">
                  <Heading as="h1" size="large" weight={400}>
                    Current balance
                    <Callout
                      a11yContentLabel="Balance information"
                      content="This is the balance you’ve accrued since your last invoice. It will be refreshed each time you get an invoice."
                      isOpen={isBalanceCalloutOpen}
                      onClose={(event) => {setIsBalanceCalloutOpen(false)}}
                      targetRef={balanceRef}
                    >
                      <IconButton a11yLabel="icon" size="medium" ref={balanceRef} onClick={() => {setIsBalanceCalloutOpen(true)}}>
                        <InfoCircle/>
                      </IconButton>
                    </Callout>
                    
                  </Heading>
                  <Body as="div" size="small" weight={400}>
                    { currentBalanceUpdated && <div className="updated">As of { formatDate(currentBalanceUpdated) }</div> }
                  </Body>
                </div>
                {currentBalanceUpdated &&
                <Metric title="" textLabel="The value shown may not reflect the latest adjustments." value={(!currentBalance && currentBalance != 0) ? `--` : `$${currentBalance}`} variant="neutral"/>}
              </CardContent>
            </Card>
          </div>
          <div className="background-box">
            <Card size="small" className="invoice-home-card invoice-history-card">
              <CardHeader className="invoice-history-header"
                title={<Heading as="h1" size="large" weight={400}>Invoice history</Heading>}
              />
              <CardContent className="invoice-history-content">
                <InvoiceHistory invoices={invoices}></InvoiceHistory>
              </CardContent>
            </Card>
          </div>
        </div>
      }
    </Page>
  );
}

export default InvoiceHome;


function fetchInvoices(restId:string):Promise<{current_balance:number, current_balance_updated_at:string, invoices:Array<any>}> {
  return new Promise((resolve, reject) => {
    ServerApi.supportApi.get(`/v1/restaurant/invoices?includeSent=true&restaurantId=${restId}`)
    .then((response:AxiosResponse) => {
      if (!response || !response.data || response.data.current_balance === undefined) { 
        reject('Server response error');
      }
      resolve(response.data);
    })
    .catch(reject);
  });
}

function formatDate(serverDate:string):string {
  // 2020-07-22 16:08 -> 7-22-2020 16:08
  
  if (!serverDate) {
    return '';
  }
  
  const date = new Date(serverDate);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  const hours = date.getHours();
  const min = date.getMinutes();
  let minutes = min.toString();
  if (minutes.length < 2) {
    minutes = '0' + minutes;
  }
  return `${month}/${day}/${year} ${hours}:${minutes}`;
}