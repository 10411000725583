import React, { useContext, useState } from 'react';
import { AxiosResponse } from 'axios';
import ServerApi from '../../lib/ServerApi';
import { BrandContext } from '../../App/App';
import {
  Alert,
  Button,
  Modal,
  ButtonGroup,
  TextField,
  Grid,
  GridColumn,
  Spinner,
} from "@walmart-web/livingdesign-components";
import { Driver } from './DriverRoster';
import { DRIVER_ADD_ERROR, DRIVER_EDIT_ERROR, formatPhoneNumber } from '../../lib/string';

import './DriverModal.scss';

interface DriverModalProps {
  sellerId: string
  driver?: Driver
  onClose:() => void
  onSuccess:(edited?:boolean) => void
}

const DriverModal: React.FunctionComponent<DriverModalProps> = props => {
  const brand = useContext(BrandContext);

  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [firstName, setFirstName] = useState(props.driver ? props.driver.first_name :'')
  const [lastName, setLastName] = useState(props.driver ? props.driver.last_name :'')
  const [phoneFormatted, setPhoneFormatted] = useState(props.driver ? formatPhoneNumber(props.driver.phone_number) :'')

  const phoneNumber = phoneFormatted.replace(/\D/g, '');
  let disabled = saving || !firstName || !lastName || !phoneFormatted || phoneFormatted.length !== 14;
  if (props.driver) {
    disabled = disabled || (
      props.driver.first_name === firstName &&
      props.driver.last_name === lastName &&
      props.driver.phone_number === phoneNumber
    )
  }

  function addDriver() {
    setSaving(true);
    createDriver(props.sellerId, {
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber
    })
    .then(()=> {
      props.onSuccess();
    })
    .catch((err => {
      setErrorMessage(brand.getString(DRIVER_ADD_ERROR));
    }))
    .finally(() => {
      setSaving(false);
    });
  }

  function editDriver() {
    if (props.driver) {
      setSaving(true);
      updateDriver(props.sellerId, {
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneFormatted.replace(/\D/g, ''),
        driver_id: props.driver.driver_id,
        deleted: props.driver.deleted,
        store_id: props.driver.store_id,
      })
      .then(()=> {
        props.onSuccess(true);
      })
      .catch((err => {
        setErrorMessage(brand.getString(DRIVER_EDIT_ERROR));
      }))
      .finally(() => {
        setSaving(false);
      });
    }
  }

  return (
    <Modal
      isOpen={true}
      onClose={props.onClose}
      size="small"
      title="Add a driver"
      actions={(
        <ButtonGroup>
          <Button size='large' variant='tertiary' onClick={props.onClose}>Cancel</Button>
          <Button size='large' variant='primary' disabled={disabled} onClick={props.driver ? editDriver : addDriver}>
          { saving ? <Spinner /> : 'Submit' }
          </Button>
        </ButtonGroup>
      )}>
        <div className="driver-modal">
          { errorMessage && <Alert variant="error">{errorMessage}</Alert>}
          <Grid hasGutter>
            <GridColumn md={6}>
              <TextField
                label='First name'
                placeholder='David'
                onChange={evt => setFirstName(evt.target.value)}
                value={firstName} />
            </GridColumn>
            <GridColumn md={6}>
              <TextField
                label='Last name'
                placeholder='Watson'
                onChange={evt => setLastName(evt.target.value)}
                value={lastName} />
            </GridColumn>
          </Grid>
          <TextField
            type='tel'
            placeholder='(___)-___-____'
            label='Phone number'
            onChange={evt => setPhoneFormatted(formatPhoneNumber(evt.target.value, phoneFormatted))}
            value={phoneFormatted} />
        </div>
    </Modal>
  )
}


function createDriver(sellerId:string, data:{first_name:string, last_name:string, phone_number:string}):Promise<void> {
  return new Promise((resolve, reject) => {
    ServerApi.supportApi.post(`/v1/drivers/${sellerId}`, data)
    .then((response:AxiosResponse) => {
      if (response && response.status === 200) {
        resolve();
      }
      else {
        reject();
      }
    })
    .catch(reject);
  });
}


function updateDriver(sellerId:string, driver:Driver):Promise<void> {
  return new Promise((resolve, reject) => {
    ServerApi.supportApi.put(`/v1/drivers/${sellerId}/${driver.driver_id}`, driver)
    .then((response:AxiosResponse) => {
      if (response && response.status === 200) {
        resolve();
      }
      else {
        reject();
      }
    })
    .catch(reject);
  });
}

export default DriverModal