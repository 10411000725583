import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, BottomSheet, Display, Body, Button, Alert, Spinner, Link } from '@walmart-web/livingdesign-components';
import { GoogleLogin } from '@react-oauth/google';
import OTPInput from '../OTPInput/OTPInput';
import { AuthContext, BrandContext, PORTRAIT_VIEW, parseError } from '../../../App/App';
import PageFooter from '../../PageFooter/PageFooter';
import Footer from '../../common/Footer/Footer';
import ServerApi from '../../../lib/ServerApi';
import { ONBOARDING_OTP_INVALID, ONBOARDING_OTP_EXPIRED, ONBOARDING_GENERAL_ERROR, ONBOARDING_OTP_MODAL_TITLE, ONBOARDING_OTP_MODAL_SUB_TITLE, LOGIN_GENERAL_ERROR } from '../../../lib/string';

import './OTPForm.scss'

interface OTPModalProps {
  isOpen: boolean,
  hashId: string,
  contactChannel: string,
  onClose: Function
}

const OTP_INVALID:string = 'DAT-104173';
const OTP_EXPIRED:string = 'DAT-104174';

const OTPModal: React.FunctionComponent<OTPModalProps> = props => {
  const auth = useContext(AuthContext);
  const brand = useContext(BrandContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [OTPValue, setOTPValue] = useState<string>('');


  function handleGoogleIdentityServiceResponse(response:{credential:string}) {
    auth.login({hashId: props.hashId, otp:OTPValue, jwt:response.credential})
    .then(() => navigate('/'))
    .catch(error => {
      const serverError:any = error && error.error ? parseError(error.error) : null;
      if (serverError) {
        if (serverError.code === OTP_INVALID) {
          setErrorMsg(brand.getString(ONBOARDING_OTP_INVALID));

          /* had to pass something other than empty string because useEffect on happy path is setting otpValue to empty string so if I pass again empty string, the useEffect won't trigger. 
          Since all none alphabets are removed when setting the value in OTP input then this won't show in the form */
          setOTPValue('x');
        }
        else if (serverError.code === OTP_EXPIRED) {
          setErrorMsg(brand.getString(ONBOARDING_OTP_EXPIRED)); 

          /* had to pass something other than empty string because useEffect on happy path is setting otpValue to empty string so if I pass again empty string, the useEffect won't trigger. 
          Since all none alphabets are removed when setting the value in OTP input then this won't show in the form */
          setOTPValue('x');
        }
        else {
          props.onClose(brand.getString(ONBOARDING_GENERAL_ERROR));
        }
      }
      else {
        props.onClose(brand.getString(ONBOARDING_GENERAL_ERROR));
      }
    })
  }


  const sendOtpCode = () => {
    if (!loading) {
      setErrorMsg('');
      setLoading(true);

      ServerApi.clientApi.post(`/v1/restaurant/signupOTP`, { id: props.hashId, channel: props.contactChannel })
        .then(() => {
          setErrorMsg('');
          setLoading(false);
        })
        .catch(() => {
          setErrorMsg(brand.getString(ONBOARDING_GENERAL_ERROR));
          setLoading(false);
        });
    }
  }

  const renderModal = () => {
    return (
      <Modal
        isOpen={props.isOpen}
        onClose={() => props.onClose(false)}
        size="large"
        title=" "
        actions={<Footer />}>
        {renderBody()}
      </Modal>
    );
  }

  const renderBottomSheet = () => {
    return (
      <BottomSheet
        isOpen={props.isOpen}
        onClose={() => props.onClose()}
        title=" ">
        {renderBody()}
        <PageFooter />
      </BottomSheet>
    );
  }

  const renderBody = () => {
    return (
      <div className="OTPForm">
        { errorMsg && <Alert variant="error" UNSAFE_className="error-alert">{errorMsg}</Alert> }

        <Display as="h3" size="small" weight={400}>
          { brand.getString(ONBOARDING_OTP_MODAL_TITLE) }
        </Display>

        <Body as="p" size="medium" weight={400}>
          { brand.getString(ONBOARDING_OTP_MODAL_SUB_TITLE) }
        </Body>

        <OTPInput 
          onOTPSubmit={ otp => setOTPValue(otp) }
          overridenValue={OTPValue} />

        <Link onClick={sendOtpCode}>{loading ? <Spinner color="gray" size="small" /> : 'Resend Code'}</Link>

        <GoogleLogin
          theme='outline'
          size='large'
          text='signin_with'
          shape='pill'
          logo_alignment='left'
          useOneTap={false}
          onSuccess={ (credentialResponse:any) => handleGoogleIdentityServiceResponse(credentialResponse) }
          onError={ () => setErrorMsg(brand.getString(LOGIN_GENERAL_ERROR)) }/>
      </div>
    );
  }

  return (
    PORTRAIT_VIEW ? renderBottomSheet() : renderModal()
  );
}

export default OTPModal;
