import React, { useContext } from 'react';
import { BrandContext } from '../../../App/App';
import { PRIVACY_LINK, TOS_LINK, HELP_EMAIL } from '../../../lib/string';

import './Footer.scss';

interface FooterProps {
  divider?:boolean
}

const Footer:React.FunctionComponent<FooterProps> = props => {
  const brand = useContext(BrandContext);

  return (
    <div className="Footer">
      <div className="links">
        <a href={ brand.getString(HELP_EMAIL) } target="_blank" rel="noopener noreferrer">Help</a>
        <a href={ brand.getString(TOS_LINK) } target="_blank" rel="noopener noreferrer">Terms</a>
        <a href={ brand.getString(PRIVACY_LINK) } target="_blank" rel="noopener noreferrer">Privacy</a>
      </div>
    </div>
  );
}

export default Footer;
