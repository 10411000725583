import React, { useContext, useState, useEffect } from 'react';
import Axios, { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import ServerApi from '../../lib/ServerApi';

import { RestaurantContext } from '../MerchantPortal/MerchantPortal';
import Page from '../Page/Page';
import {
  Heading,
  Button,
  Card,
  CardHeader,
  CardContent,
  Body,
  Spinner,
  Breadcrumb,
  BreadcrumbItem,
  Alert,
} from "@walmart-web/livingdesign-components";
import { GENERAL_CONTACT_SUPPORT_ERROR, GENERAL_TRY_AGAIN_ERROR, LANDING_PAYOUT_TITLE, LANDING_HEADER } from '../../lib/string';
import { BrandContext } from '../../App/App';

import './PayoutHome.scss';

const UPDATE_OPTION = {
  TAX: "tax",
  BUSINESS_ADDRESS: "business_address"
}

interface DepositData {
  data_fields:Array<any>,
  deposit_instruments:Array<any>,
  instant_payout_status:any,
  email:string
}

interface PayoutHomeProps {
}

const PayoutHome: React.FunctionComponent<PayoutHomeProps> = props => {
  const brand = useContext(BrandContext);
  const restaurant = useContext(RestaurantContext);

  const [loadingDepositData, setLoadingDepositData] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [depositData, setDepositData] = useState<DepositData>({data_fields:[], deposit_instruments:[], instant_payout_status:{}, email:''});
  const navigate = useNavigate()

  const getParentURL = () => {
    const baseURL = window.location.href;
    return baseURL.slice(0, baseURL.lastIndexOf('/') + 1)
  }

  const navigateToPayoutSetup = () => {
    navigate(`/${restaurant.restaurant_id}/payout-setup`);
  }

  const navigateToUpdatePayoutPage = (field?: string) => {
    const needTax = (field === UPDATE_OPTION.TAX) || (needTaxDetails && !field);
    const needAddress = (field === UPDATE_OPTION.BUSINESS_ADDRESS) || (needBusinessAddressDetails && !field);

    navigate(`/${restaurant.restaurant_id}/update-payout`, {state: {needTaxDetails: needTax, needBusinessAddressDetails: needAddress}});
  }

  const needsTaxDetails = (data_fields:Array<any>): boolean => {
    return !!data_fields.find((f:any) => {
      return (f.name === 'tax_id_number' || f.name === 'company_name' || f.name === 'federal_tax_classification') && f.status === 'need';
    });
  }

  const needsBusinessAddressDetails = (data_fields:Array<any>): boolean => {
    return !!data_fields.find((f:any) => {
      return (f.name === 'line1' || f.name === 'city' || f.name === 'state' || f.name === 'postal_code' || f.name === 'country') && f.status === 'need';
    });
  }

  function fetchDepositData(restId:string):Promise<any> {
    return new Promise((resolve, reject) => {
      ServerApi.clientApi.get(`/v2/payment/restaurantDepositInstrument?restaurantId=${restId}`)
      .then((response:AxiosResponse) => {
        if (!response || !response.data || !response.data.deposit_instruments) { 
          setErrorMessage(brand.getString(GENERAL_CONTACT_SUPPORT_ERROR));
          reject();
        } else {
          resolve(response.data);
        }
      })
      .catch(error => {
        setErrorMessage(brand.getString(GENERAL_TRY_AGAIN_ERROR));
        reject();
      });
    });
  }
  
  // Fetch the deposit instruments etc.
  useEffect(() => {
    const updateDepositData = () => {
      setLoadingDepositData(true);
      fetchDepositData(restaurant.restaurant_id)
      .then((data) => {
        // For initial payout setup
        if (!data.deposit_instruments.length) {
          setLoadingDepositData(false);
          navigateToPayoutSetup();
        } else {
          setDepositData(data);
          setLoadingDepositData(false);
        }
      })
      .catch(err => {
        setErrorMessage(brand.getString(GENERAL_CONTACT_SUPPORT_ERROR));
        setLoadingDepositData(false);
      });
    }
    updateDepositData();
  }, [restaurant.restaurant_id]);

  if (loadingDepositData) {
    return <Spinner />;
  }

  const needTaxDetails: boolean = needsTaxDetails(depositData.data_fields);
  const needBusinessAddressDetails: boolean = needsBusinessAddressDetails(depositData.data_fields);
  
  const instrument = depositData.deposit_instruments[0]? depositData.deposit_instruments[0]: {bank_name: "", last4:"", user_name:""};

  return (
    <Page  className='PayoutHome' title={brand.getString(LANDING_PAYOUT_TITLE)} onReturnClick={() => navigate(`/${restaurant.restaurant_id}`)}>
      <Breadcrumb className="breadcrumb">
        <BreadcrumbItem href={getParentURL()}>
          {brand.getString(LANDING_HEADER)}
        </BreadcrumbItem>
        <BreadcrumbItem href={getParentURL() + "payout"} isCurrent>
          {brand.getString(LANDING_PAYOUT_TITLE)}
        </BreadcrumbItem>
      </Breadcrumb>
      { errorMessage && <Alert variant="error">{errorMessage}</Alert>}
      <div>
        <Heading className="heading" as="h1" size="large" weight={700}>Payout Details</Heading>
        <Card size="large" className='bank-card'>
          <CardHeader
              title={<Heading as="h1" size="medium" weight={400}>{instrument.bank_name}</Heading>}
              trailing={
                <Button variant="tertiary" onClick={() => navigateToPayoutSetup()}>Edit</Button>
              }
            />
          <CardContent>
            <div className="account">
              <Body as="p" size="medium" weight={400}>
                Account Ending in {instrument.last4}
              </Body>
            </div>
            <Body as="p" size="large" weight={700}>
              {instrument.user_name}
            </Body>
          </CardContent>
        </Card>

        <div className='information-cards'>
          <Card size="small" className="information-card">
            <CardHeader
              title={<Heading as="h1" size="medium" weight={400}>Tax details</Heading>}
              trailing={
                <Button variant="tertiary" onClick={() => navigateToUpdatePayoutPage(UPDATE_OPTION.TAX)}>Edit</Button>
              }
            />
            <CardContent>
              {needTaxDetails &&
                <Alert
                  actionButtonProps={{
                    children: "Update",
                    onClick:() => navigateToUpdatePayoutPage()
                  }}
                  variant="error"
                >
                  Your tax details aren&rsquo;t valid.
                </Alert>
              }
            </CardContent>
          </Card>
          <Card size="small" className="information-card">
            <CardHeader
              title={<Heading as="h1" size="medium" weight={400}>Business address</Heading>}
              trailing={
                <Button variant="tertiary" onClick={() => navigateToUpdatePayoutPage(UPDATE_OPTION.BUSINESS_ADDRESS)}>Edit</Button>
              }
            />
            <CardContent>
              {needBusinessAddressDetails &&
                <Alert
                  actionButtonProps={{
                    children: "Update",
                    onClick: () => navigateToUpdatePayoutPage()
                  }}
                  variant="error"
                >
                  Your business address isn&rsquo;t valid.
                </Alert>
              }
            </CardContent>
          </Card>
        </div>
      </div>
    </Page>
  );
}

export default PayoutHome;
