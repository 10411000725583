/**
 * Project Wiki - 
 * https://foodrunn.atlassian.net/wiki/spaces/PROD/pages/1176698885/Payment+to+restaurants+via+Stripe
 */

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { HashRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import properties from '../lib/properties';
import ServerApi from '../lib/ServerApi';
import analytics from '../lib/analytics';
import getString, { SITE_TAB_ICON, SITE_TAB_TITLE } from '../lib/string';
import RestaurantPicker from '../components/RestaurantPicker/RestaurantPicker';
import MerchantPortal from '../components/MerchantPortal/MerchantPortal';
import Messaging from '../components/Messaging/Messaging';
import Login, { LoginOptions } from '../components/Login/Login';
import Onboarding from '../components/Onboarding/Onboarding';
import Logout, { LOGOUT_PATH } from '../components/Logout/Logout';
import { SnackbarProvider } from '@walmart-web/livingdesign-components';
import security, { RegisterType } from '../lib/security';

import golocalImg from './../images/golocal-logo.svg';
import joyrunImg from './../images/wordmark-wh.png';

import './App.scss';

type BrandType = {
	name: 'JoyRun' | 'GoLocal'
	logo: string
	getString: (id:string) => string
}

declare global { 
  interface Window {
		env: {
			REACT_APP_ENV: 'local' | 'dev' | 'stage' | 'production'
		},
		gapi:any,
    googleAuth2Loaded:boolean,
	}
}

let u = sessionStorage.user ? JSON.parse(sessionStorage.user) : {};

const fpPromise = FingerprintJS.load();

export const PORTRAIT_VIEW:boolean = window.innerWidth < 600;

export const AppConfig = {
	user: u,

	utcToLocal: (utcTime : string) => { 
		if (!utcTime.match(/Z$/)) {
			utcTime = utcTime + ' Z';
		}
		let d = new Date(utcTime);
		return d.toString().replace(/\sGMT-\d\d\d\d/, '');
	},

	getPrivacyPolicyLink: () => {
		return 'https://corporate.walmart.com/privacy-security/walmart-marketplace-seller-privacy-policy';
	},

	notify: (msg : string, callback : any = null, details : string = '') => {
		// Overload this function in messaing.tsx
	},

	warn: (msg : string, callback : any = null, details : string = '', forceAccept : any = null) => {
		// Overload this function in messaging.tsx
	},

	error: () => {
		// Overlord this function in messaging.tsx
	},

	closeMessage: () => {
		// Overload this funciton in messaging.tsx
	}
};


export const MOBILE_SIZE : number = 500;



//
// Brand Context
//
const isJR = window.location.hostname.includes('joyrun');
const brand:BrandType = {
	name: isJR ? 'JoyRun' : 'GoLocal',
	logo: isJR ? joyrunImg : golocalImg,
	getString: (id:string) => getString(id, isJR)
};
export const BrandContext = React.createContext(brand);

//
// Update Tab icon and title
//
const link = document.createElement('link');
link.rel = 'icon';
link.href = getString(SITE_TAB_ICON, isJR);
document.getElementsByTagName('head')[0].appendChild(link);
document.title = getString(SITE_TAB_TITLE, isJR);

function App() {

	return (
		<div id="app" className="App">
			<Messaging />
			<AuthProvider>
				<BrandContext.Provider value={ brand }>
					<SnackbarProvider>
						<HashRouter>
							<Routes>
								<Route path="/login" element={ <Login /> } />
								<Route path={ LOGOUT_PATH } element={ <Logout /> }>
									<Route path="expired" element={ <Logout expired={ true } /> } />
									<Route path=":reason" element={ <Logout /> }>
										<Route path="expired" element={ <Logout expired={ true } /> } />
									</Route>
								</Route>
								<Route path="/onboarding" element={
									<Onboarding />
								}/>
								<Route path="/" element={
									<RequireAuth>
										<RestaurantPicker />
									</RequireAuth>
								}/>
								<Route path="/:restId/*" element={
									<RequireAuth>
										<MerchantPortal />
									</RequireAuth>
								}/>
							</Routes>
						</HashRouter>
					</SnackbarProvider>
					
				</BrandContext.Provider>
			</AuthProvider>
		</div>
	);
}


interface AuthContextType {
  user: any;
	setUser: Function;
	deviceId: string;
	login: (opt:LoginOptions) => Promise<any>;
}

export const AuthContext = React.createContext<AuthContextType>(null!);
function AuthProvider({ children }: { children: React.ReactNode }) {
	const existingUser = properties.getItem(properties.PROPERTY_USER);

  const [user, setUser] = React.useState<any>(existingUser || {});
	const [deviceId, setDeviceId] = useState('');


	//
	// Set API headers when user changes
	//
	const setUserHeaders = useCallback(() => {
		if (user && user.dasher_user_id) {
      analytics.initUser(user);
      ServerApi.setHeader(ServerApi.HEADER_USER_TOKEN, user.dasher_user_id);
      ServerApi.setHeader(ServerApi.HEADER_SESSION_TOKEN, user.session_token);
      properties.setItem(properties.PROPERTY_USER_TOKEN, user.dasher_user_id);

      if (!properties.getItem(properties.PROPERTY_SESSION_TOKEN)) {
        properties.setItem(properties.PROPERTY_SESSION_TOKEN_EXPIRY, Date.now().toString());
      }
    }
	}, [user]);

	setUserHeaders();

  useEffect(() => {
    setUserHeaders();
  }, [setUserHeaders]);


	//
	// Get a device ID
	//
	useEffect(() => {
		fpPromise
    .then(fp => fp.get())
    .then(result => {
			setDeviceId(result.visitorId);
			ServerApi.setHeader(ServerApi.HEADER_DEVICE_ID, deviceId);
		});
	}, [deviceId]);


	//
	// Login function
	//
	const login = (options:LoginOptions):Promise<any> => {
		return new Promise((resolve, reject) => {
			let register:RegisterType|undefined = undefined;
			if (options.hashId && options.otp) {
				register = {
					restaurant_id_string: options.hashId,
					restaurant_otp: options.otp,
				};
			}

			security.authenticate(user, deviceId, {register, jwt:options.jwt || ''})
			.then(userData => {
				setUser(userData);
				analytics.track(analytics.TRACK_USER_LOGIN, {
					userId: userData.dasher_user_id,
				});
				resolve(userData);
			})
			.catch(err => {
				console.error(err);
				security.logoutUser(user, () => {
					reject(err);
				}, 'register-error', false, true);
			});
		});
	}

  const value = {user, setUser, deviceId, login};
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}


function RequireAuth({ children }: { children: JSX.Element }) {
	const location = useLocation();
	const auth = useContext(AuthContext);

  if (!auth.user || !auth.user.dasher_user_id) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
	else if (!security.verifySession()) {
		// User is logged in but session about to expire. Gracefully log them out.
		security.logoutUser(auth.user, () => {}, 'session-expired');
		return <></>;
	}
  return children;
}


export const parseError = (err:any):{code:string, message:string} => {
	let code = '';
	let message = '';
	if (err && err.response && err.response.data) {
		err = err.response.data;
	}
	if (err && (err.errorCode || err.error_code)) {
		code = err.errorCode || err.error_code;
	}
	if (code && code.match(/DAT-2/) && err.message) {
		message = err.message;
	}
	return { code, message };
}

export default App;
