import React, { useContext } from 'react'
import { Link, Navigate } from 'react-router-dom';
import { Body, Display, List, ListItem, Modal } from '@walmart-web/livingdesign-components';
import { AuthContext } from '../../App/App';
import ModalFooter from '../ModalFooter/ModalFooter';

import restaurantImageYellow from '../../images/restaurant_image_yellow.svg'
import restaurantImageGreen from '../../images/restaurant_image_green.svg'

import './RestaurantPicker.scss';


interface RestaurantPickerProps {
  title?:string,
}


export default function (props:RestaurantPickerProps) {
  const [isOpen, setIsOpen] = React.useState(true);
  const title = props.title ? props.title : "Choose a store";

  const auth = useContext(AuthContext);
	const ownedRestaurants = auth.user.owned_restaurant_details;

	let restaurantList:Array<any> = [];
	if (ownedRestaurants) {
		restaurantList = Object.keys(ownedRestaurants).map((id:string) => ({
			restaurant_id:ownedRestaurants[id].restaurant_id, name: ownedRestaurants[id].name
		}));
	}

	if (restaurantList.length === 1) {
		return <Navigate to={ `/${restaurantList[0].restaurant_id}` } />;
  }

  else {
    return <Modal
      onClose={() => setIsOpen(false)}
      size="medium"
      title=" "
      isOpen={isOpen}
      className="RestaurantPicker">
        <Display as="h2" size="large" className="header">{title}</Display>
        <List className="list">
          {renderRestaurants(restaurantList)}
          <ListItem> </ListItem>
        </List>
        <ModalFooter />
    </Modal>;
  }
}


function renderRestaurants(restaurants: any) {
  return restaurants.map((r:any, i:number) => {
    const pieces = r.name.split(' at ');
    let restaurantName = '';
    let restaurantLocation = '';

    if (pieces) {
      restaurantName = pieces[0];
      restaurantLocation = pieces[1];
    }

    return <ListItem leading={renderRestaurantImage(i, restaurantName)}>
      <Link className="restaurant" key={r.id} to={`/${r.restaurant_id}`} >
        <Body as="p" size="medium" weight={700}>{restaurantName}</Body>
        <Body as="p" size="small">{restaurantLocation}</Body>
      </Link>
    </ListItem>;
  })
}


function renderRestaurantImage(i:number, name:string) {
  const restaurantImage = i % 2 ? restaurantImageGreen : restaurantImageYellow
  return <img className="restaurantImage" src={restaurantImage} alt={name} />
}
