export const PROGRAM_WALMART = "GOLOCAL";
export const PROGRAM_JOYRUN = "JOYRUN";
export const PRIVACY_LINK = "PRIVACY_LINK";
export const TOS_LINK = "TOS_LINK";
export const HELP_EMAIL = "HELP_EMAIL";

export const SITE_TAB_TITLE = "SITE_TAB_TITLE";
export const SITE_TAB_ICON = "SITE_TAB_ICON";

export const LANDING_HEADER = "LANDING_HEADER";
export const LANDING_SUBHEADER_PAYOUT = "LANDING_SUBHEADER_PAYOUT";
export const LANDING_SUBHEADER_CATALOG = "LANDING_SUBHEADER_CATALOG";
export const LANDING_PAYOUT_TITLE = "LANDING_PAYOUT_TITLE";
export const LANDING_PAYOUT_AVAILABLE_DESCRIPTION = "LANDING_PAYOUT_AVAILABLE_DESCRIPTION";
export const LANDING_PAYOUT_UNAVAILABLE_DESCRIPTION = "LANDING_PAYOUT_UNAVAILABLE_DESCRIPTION";
export const LANDING_INVOICE_TITLE = "LANDING_INVOICE_TITLE";
export const LANDING_INVOICE_AVAILABLE_DESCRIPTION = "LANDING_INVOICE_AVAILABLE_DESCRIPTION";
export const LANDING_INVOICE_UNAVAILABLE_DESCRIPTION = "LANDING_INVOICE_UNAVAILABLE_DESCRIPTION";
export const LANDING_CATALOG_TITLE = "LANDING_CATALOG_TITLE";
export const LANDING_CATALOG_AVAILABLE_DESCRIPTION = "LANDING_CATALOG_AVAILABLE_DESCRIPTION";
export const LANDING_CATALOG_UNAVAILABLE_DESCRIPTION = "LANDING_CATALOG_UNAVAILABLE_DESCRIPTION";
export const LANDING_ORDER_TITLE = "LANDING_ORDER_TITLE";
export const LANDING_ORDER_AVAILABLE_DESCRIPTION = "LANDING_ORDER_AVAILABLE_DESCRIPTION";
export const LANDING_ORDER_UNAVAILABLE_DESCRIPTION = "LANDING_ORDER_UNAVAILABLE_DESCRIPTION";
export const LANDING_DRIVER_TITLE = "LANDING_DRIVER_TITLE";
export const LANDING_DRIVER_DESCRIPTION = "LANDING_DRIVER_DESCRIPTION";
export const LANDING_CONFIG_API_ERROR = "LANDING_CONFIG_API_ERROR";

export const HOMEPAGE_DASHBOARD_CONFIG_API_ERROR = "HOMEPAGE_DASHBOARD_CONFIG_API_ERROR";

export const ONBOARDING_TITLE = 'ONBOARDING_TITLE';
export const ONBOARDING_SUB_TITLE = 'ONBOARDING_SUB_TITLE';
export const ONBOARDING_SMS_CONSENT_MSG = 'ONBOARDING_SMS_CONSENT_MSG';
export const ONBOARDING_OTP_INVALID = 'ONBOARDING_OTP_INVALID';
export const ONBOARDING_OTP_EXPIRED = 'ONBOARDING_OTP_EXPIRED';
export const ONBOARDING_OTP_MODAL_TITLE = 'ONBOARDING_OTP_MODAL_TITLE';
export const ONBOARDING_OTP_MODAL_SUB_TITLE = 'ONBOARDING_OTP_MODAL_SUB_TITLE';
export const ONBOARDING_GENERAL_ERROR = 'ONBOARDING_GENERAL_ERROR';
export const ONBOARDING_NO_CONTACT_OPTIONS = 'ONBOARDING_NO_CONTACT_OPTIONS';

export const LOGIN_TITLE = "LOGIN_TITLE";
export const LOGIN_GENERAL_ERROR = "LOGIN_GENERAL_ERROR";
export const LOGIN_UNAUTHORIZED_ERROR = "LOGIN_UNAUTHORIZED_ERROR";

export const INVOICES_TITLE = "INVOICES_TITLE";
export const INVOICES_NO_INVOICES_FOUND = "INVOICES_NO_INVOICES_FOUND";
export const INVOICES_FETCH_API_ERROR = "INVOICES_FETCH_API_ERROR";

export const GENERAL_CONTACT_SUPPORT_ERROR = "GENERAL_CONTACT_SUPPORT_ERROR";
export const GENERAL_TRY_AGAIN_ERROR = "GENERAL_TRY_AGAIN_ERROR";
export const GENERAL_TRY_SIGN_IN_AGAIN_ERROR = "GENERAL_TRY_SIGN_IN_AGAIN_ERROR";

export const PAYOUT_SUB_TITLE = "PAYOUT_SUB_TITLE";
export const PAYOUT_CREATE_PAYMENT_SUCCESS = "PAYOUT_CREATE_PAYMENT_SUCCESS";
export const PAYOUT_CREATE_PAYMENT_ERROR = "PAYOUT_CREATE_PAYMENT_ERROR";
export const PAYOUT_UPDATE_PAYMENT_SUCCESS = "PAYOUT_UPDATE_PAYMENT_SUCCESS";
export const PAYOUT_UPDATE_PAYMENT_ERROR = "PAYOUT_UPDATE_PAYMENT_ERROR";
export const PAYOUT_UPLOAD_DOCUMENT_TOO_LARGE_ERROR = "PAYOUT_UPLOAD_DOCUMENT_TOO_LARGE_ERROR";
export const PAYOUT_UPLOAD_DOCUMENT_UNSUPPORTED_ERROR = "PAYOUT_UPLOAD_DOCUMENT_UNSUPPORTED_ERROR";

export const DRIVER_LOAD_ERROR = "DRIVER_LOAD_ERROR";
export const DRIVER_ADD_ERROR = "DRIVER_ADD_ERROR";
export const DRIVER_EDIT_ERROR = "DRIVER_EDIT_ERROR";
export const DRIVER_REMOVE_ERROR = "DRIVER_REMOVE_ERROR";


const DEFAULT = "DEFAULT";
const WALMART_SUPPORT_NUMBER = "(877) 471-0022";
const JOYRUN_SUPPORT_NUMBER = "(844) 651-1395";

const STRING_DICT: any = {
  [SITE_TAB_TITLE]: {
    [PROGRAM_WALMART]: "Local Marketplace Seller Portal",
    [PROGRAM_JOYRUN]: "JoyRun Merchant Portal",
  },
  [SITE_TAB_ICON]: {
    [PROGRAM_WALMART]: "/seller/golocal.png",
    [PROGRAM_JOYRUN]: "/seller/favicon.png",
  },
  [LANDING_HEADER]: {
    [PROGRAM_WALMART]: "Local Marketplace Seller Portal",
    [PROGRAM_JOYRUN]: "Merchant Portal",
  },
  [LANDING_SUBHEADER_PAYOUT]: {
    [DEFAULT]: "Before you go live, you need to set up a payout method."
  },
  [LANDING_SUBHEADER_CATALOG]: {
    [DEFAULT]: "We'll let you know when your catalog is ready for preview.",
  },
  [LANDING_PAYOUT_TITLE]: {
    [DEFAULT]: "Payout",
  },
  [LANDING_PAYOUT_AVAILABLE_DESCRIPTION]: {
    [DEFAULT]: "Edit your payout method and tax details.",
  },
  [LANDING_PAYOUT_UNAVAILABLE_DESCRIPTION]: {
    [DEFAULT]: "Set up your payout method and tax details.",
  },
  [LANDING_INVOICE_TITLE]: {
    [DEFAULT]: "Current Balance & Invoices",
  },
  [LANDING_INVOICE_AVAILABLE_DESCRIPTION]: {
    [DEFAULT]: "View your current balance and invoices.",
  },
  [LANDING_INVOICE_UNAVAILABLE_DESCRIPTION]: {
    [PROGRAM_WALMART]: "You don't have any invoices from Local Marketplace yet.",
    [PROGRAM_JOYRUN]: "You don't have any invoices from JoyRun yet.",
  },
  [LANDING_CATALOG_TITLE]: {
    [PROGRAM_WALMART]: "Catalog",
    [PROGRAM_JOYRUN]: "Menu",
  },
  [LANDING_CATALOG_AVAILABLE_DESCRIPTION]: {
    [PROGRAM_WALMART]: "Preview your catalog as customers will see it in the Walmart app.",
    [PROGRAM_JOYRUN]: "Preview your menu as customers will see it in the JoyRun app.",
  },
  [LANDING_CATALOG_UNAVAILABLE_DESCRIPTION]: {
    [PROGRAM_WALMART]: "We're building your catalog. It will be available for preview once processed.",
    [PROGRAM_JOYRUN]: "We're building your menu. It will be available for preview once processed.",
  },
  [LANDING_ORDER_TITLE]: {
    [DEFAULT]: "Order Manager",
  },
  [LANDING_ORDER_AVAILABLE_DESCRIPTION]: {
    [DEFAULT]: "View and manage the orders you receive.",
  },
  [LANDING_ORDER_UNAVAILABLE_DESCRIPTION]: {
    [DEFAULT]: "View and manage the orders you receive.",
  },
  [LANDING_DRIVER_TITLE]: {
    [DEFAULT]: "Driver Roster",
  },
  [LANDING_DRIVER_DESCRIPTION]: {
    [DEFAULT]: "Manage your fleet of drivers.",
  },
  [LANDING_CONFIG_API_ERROR]: {
    [PROGRAM_WALMART]: `Sorry, there was a problem. Please try again or reach out to support at ${WALMART_SUPPORT_NUMBER}.`,
    [PROGRAM_JOYRUN]: `Sorry, there was a problem. Please try again or reach out to support at ${JOYRUN_SUPPORT_NUMBER}.`,
  },
  [HOMEPAGE_DASHBOARD_CONFIG_API_ERROR]: {
    [PROGRAM_WALMART]: `Sorry, there was a problem. Please try again or reach out to support at ${WALMART_SUPPORT_NUMBER}.`,
    [PROGRAM_JOYRUN]: `Sorry, there was a problem. Please try again or reach out to support at ${JOYRUN_SUPPORT_NUMBER}.`,
  },
  [ONBOARDING_TITLE]: {
    [PROGRAM_WALMART]: "Welcome to Local Marketplace!",
    [PROGRAM_JOYRUN]: "Welcome to JoyRun!",
  },
  [ONBOARDING_SUB_TITLE]: {
    [DEFAULT]: "To create your Merchant account, first verify your identity so we can make sure it's you.",
  },
  [ONBOARDING_SMS_CONSENT_MSG]: {
    [PROGRAM_WALMART]: `By clicking "Send code", you agree to let Walmart Local Marketplace send you a text message. Message and data rates may apply.`,
    [PROGRAM_JOYRUN]: `By clicking "Send code", you agree to let JoyRun send you a text message. Message and data rates may apply.`,
  },
  [ONBOARDING_OTP_INVALID]: {
    [DEFAULT]: "This verification code is invalid. Try checking your code and re-entering it or request a new code."
  },
  [ONBOARDING_OTP_EXPIRED]: {
    [DEFAULT]: "This verification code has expired. Please request another code and try again."
  },
  [ONBOARDING_GENERAL_ERROR]: {
    [PROGRAM_WALMART]: `Sorry, something went wrong. Please try again. If you're still having trouble, reach out to support at ${WALMART_SUPPORT_NUMBER}`,
    [PROGRAM_JOYRUN]: `Sorry, something went wrong. Please try again. If you're still having trouble, reach out to support at ${JOYRUN_SUPPORT_NUMBER}`,
  },
  [ONBOARDING_NO_CONTACT_OPTIONS]: {
    [DEFAULT]: "There was a problem loading your information. Please try again later."
  },
  [ONBOARDING_OTP_MODAL_TITLE]: {
    [DEFAULT]: "Enter verification code"
  },
  [ONBOARDING_OTP_MODAL_SUB_TITLE]: {
    [DEFAULT]: "After verifying your account, click the Google button below to sign in to the Merchant Portal using your store's new Google account."
  },
  [LOGIN_TITLE]: {
    [PROGRAM_WALMART]: `Sign in to Local Marketplace Merchant Portal`,
    [PROGRAM_JOYRUN]: `Sign in to JoyRun Merchant Portal`,
  },
  [LOGIN_GENERAL_ERROR]: {
    [PROGRAM_WALMART]: `Sorry, we're having trouble signing you in. Please try again. If you're still having trouble, contact support at ${WALMART_SUPPORT_NUMBER}`,
    [PROGRAM_JOYRUN]: `Sorry, we're having trouble signing you in. Please try again. If you're still having trouble, contact support at ${JOYRUN_SUPPORT_NUMBER}`,
  },
  [LOGIN_UNAUTHORIZED_ERROR]: {
    [DEFAULT]: "Sorry, you're not authorized to view this content. To request access, reach out to support."
  },
  [INVOICES_TITLE]: {
    [DEFAULT]: "Your Invoices"
  },
  [INVOICES_NO_INVOICES_FOUND]: {
    [PROGRAM_WALMART]: "You don't have any invoices from Local Marketplace yet.",
    [PROGRAM_JOYRUN]: "You don't have any invoices from JoyRun yet."
  },
  [INVOICES_FETCH_API_ERROR]: {
    [PROGRAM_WALMART]: `Sorry, we're unable to load your invoices. Please try reloading the page. If that doesn't work, reach out to support at ${WALMART_SUPPORT_NUMBER}.`,
    [PROGRAM_JOYRUN]: `Sorry, we're unable to load your invoices. Please try reloading the page. If that doesn't work, reach out to support at ${JOYRUN_SUPPORT_NUMBER}.`
  },
  [GENERAL_CONTACT_SUPPORT_ERROR]: {
    [PROGRAM_WALMART]: `Sorry, there was a problem. Please reach out to support at ${WALMART_SUPPORT_NUMBER}.`,
    [PROGRAM_JOYRUN]: `Sorry, there was a problem. Please reach out to support at ${JOYRUN_SUPPORT_NUMBER}.`
  },
  [GENERAL_TRY_AGAIN_ERROR]: {
    [DEFAULT]: "Sorry, there was a problem. Please try again."
  },
  [GENERAL_TRY_SIGN_IN_AGAIN_ERROR]: {
    [DEFAULT]: "Sorry, there was a problem. Please sign out and back in."
  },
  [PAYOUT_SUB_TITLE]: {
    [DEFAULT]: "Set up payout method"
  },
  [PAYOUT_CREATE_PAYMENT_SUCCESS]: {
    [DEFAULT]: "Your details were submitted and are being verified."
  },
  [PAYOUT_CREATE_PAYMENT_ERROR]: {
    [PROGRAM_WALMART]: `There was a problem submitting your information. Please try again. If you're still having trouble, reach out to support at ${WALMART_SUPPORT_NUMBER}.`,
    [PROGRAM_JOYRUN]: `There was a problem submitting your information. Please try again. If you're still having trouble, reach out to support at ${JOYRUN_SUPPORT_NUMBER}.`
  },
  [PAYOUT_UPDATE_PAYMENT_SUCCESS]: {
    [DEFAULT]: "Your details were submitted and are being verified."
  },
  [PAYOUT_UPDATE_PAYMENT_ERROR]: {
    [PROGRAM_WALMART]: `There was a problem submitting your information. Please try again. If you're still having trouble, reach out to support at ${WALMART_SUPPORT_NUMBER}.`,
    [PROGRAM_JOYRUN]: `There was a problem submitting your information. Please try again. If you're still having trouble, reach out to support at ${JOYRUN_SUPPORT_NUMBER}.`
  },
  [PAYOUT_UPLOAD_DOCUMENT_TOO_LARGE_ERROR]: {
    [DEFAULT]: "This file is too large. The maximum image size is 7 MB.",
  },
  [PAYOUT_UPLOAD_DOCUMENT_UNSUPPORTED_ERROR]: {
    [DEFAULT]: "This file format is not supported. We only support JPG and PNG.",
  },
  [DRIVER_LOAD_ERROR]: {
    [DEFAULT]: `There was a problem loading the driver list. Please try again. If you're still having trouble, reach out to support at ${WALMART_SUPPORT_NUMBER}.`,
  },
  [DRIVER_ADD_ERROR]: {
    [DEFAULT]: `There was a problem adding the driver. Please try again. If you're still having trouble, reach out to support at ${WALMART_SUPPORT_NUMBER}.`,
  },
  [DRIVER_EDIT_ERROR]: {
    [DEFAULT]: `There was a problem updating the driver. Please try again. If you're still having trouble, reach out to support at ${WALMART_SUPPORT_NUMBER}.`,
  },
  [DRIVER_REMOVE_ERROR]: {
    [DEFAULT]: `There was a problem removign the driver. Please try again. If you're still having trouble, reach out to support at ${WALMART_SUPPORT_NUMBER}.`,
  },
  [PRIVACY_LINK]: {
    [PROGRAM_WALMART]: `https://www.walmartgolocal.com/content/go-local/privacy-policy.html`,
    [PROGRAM_JOYRUN]: `https://corporate.walmart.com/privacy-security/walmart-marketplace-seller-privacy-policy`
  },
  [TOS_LINK]: {
    [PROGRAM_WALMART]: `https://www.walmartgolocal.com/content/go-local/terms-of-use.html`,
    [PROGRAM_JOYRUN]: `https://www.joyrun.com/merchant-terms`
  },
  [HELP_EMAIL]: {
    [PROGRAM_WALMART]: `mailto:localsellerhelp@customercare.walmart.com`,
    [PROGRAM_JOYRUN]: `mailto:help@joyrun.com`
  }
};


export default function getString(id:string, isJR: boolean, args:string[] = []):string {
  const programType = isJR ? PROGRAM_JOYRUN : PROGRAM_WALMART;
  if (id) {
      let str = STRING_DICT[id][programType] || STRING_DICT[id][DEFAULT];

      // if any string argument passed, replace it in the string before returning
      // ex: Hello {0} args = ["JR"] then str = Hello JR
      for (let i = 0; args && i < args.length; i++) {
          str = str.replace(`{${i}}`, args[i]);
      }
      return str;
  }
  return '';
}

export const formatPhoneNumber = (value:string, previousValue = ''):string => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  
  if (!previousValue || value.length > previousValue.length) {
    if (currentValue.length < 4) return currentValue;
    if (currentValue.length < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
  } else {
    return value;
  }
}