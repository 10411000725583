import { Body, Button, ButtonGroup } from '@walmart-web/livingdesign-components';
import React, { useState, useContext } from 'react';
import { BrandContext } from '../../App/App';
import { INVOICES_NO_INVOICES_FOUND, INVOICES_TITLE } from '../../lib/string';

import './InvoiceHistory.scss';


interface InvoiceHistoryProps {
  invoices:Array<any>,
}


const InvoiceHistory:React.FunctionComponent<InvoiceHistoryProps> = (props:any) => {
  const brand = useContext(BrandContext);
  const [dateSort, setDateSort] = useState<'new2old'|'old2new'>('new2old');

  const toggleDateSort = () => {
    setDateSort(dateSort === 'new2old' ? 'old2new' : 'new2old');
  }


  const sortInvoices = (invoices:Array<any>):Array<any>  =>{
    const toSort = invoices.slice(0);
    toSort.sort((a, b) => {
      if (dateSort === 'new2old') {
        return a.start_date > b.start_date ? -1 : 1;
      }
      else {
        return a.start_date <= b.start_date ? -1 : 1;
      }
    });
    return toSort;
  }


  const renderTableHeader = () => {
    return (
      <div className="table-header">
        <div className="transaction-dates table-cell"><Body size="small" weight={700}>Transaction Dates</Body></div>
        <div className="status table-cell"><Body size="small" weight={700}>Status</Body></div>
        <div className="payout-date table-cell"><Body size="small" weight={700}>Payout Date</Body></div>
        <div className="net-payout table-cell"><Body size="small" weight={700}>Net payout</Body></div>
        <div className="actions table-cell"></div>
      </div>
    );
  }
  
  
  const renderInvoices = (invoice:any) => {
    const basePath = '/seller/documents/invoices/';
    const startDate = formatDate(invoice.start_date);
    const endDate = formatDate(invoice.end_date);
   
    const paidDate = invoice.invoice_paid_at ? formatDate(invoice.invoice_paid_at) : "--";
    const paidOut = invoice.paid_out ? `Paid out` : 'Pending';
    const netPayout = invoice.paid_amount ? `$${invoice.paid_amount}` : `--`;

    const tabInvoiceLink = invoice.tab_invoice_for_restaurant_link || '';
    const ordersLink = invoice.tab_item_details_link ? invoice.tab_item_details_link : invoice.item_details_link || '';

    return (
      <div key={invoice.id} className="invoice">
        <div className="transaction-dates table-cell"><Body size="small" weight={400}>{ startDate } - { endDate }</Body></div>
        <div className="status table-cell"><Body size="small" weight={400}>{ paidOut }</Body></div>
        <div className="payout-date table-cell"><Body size="small" weight={400}>{ paidDate }</Body></div>
        <div className="net-payout table-cell"><Body size="small" weight={400}>{ netPayout }</Body></div>
        <div className="actions table-cell scroll">
          <ButtonGroup>
            <Button href={ basePath + tabInvoiceLink } target="_blank">Invoice</Button>
            <Button href={ basePath + ordersLink } target="_blank">Order Details</Button>
          </ButtonGroup>
        </div>
      </div>
    );
  }

  const invoices = sortInvoices(props.invoices);
  return (
    <div className="InvoiceHistory">
      { invoices.length > 0 && renderTableHeader() }
      <div className="invoices">
        { !invoices.length && <p>{ brand.getString(INVOICES_NO_INVOICES_FOUND) }</p> }
        { invoices.map(renderInvoices) }
      </div>
    </div>
  );
}

function formatDate(d:string):string {
  const date = new Date(d);
  const year = date.getFullYear().toString().substring(2)
  return `${date.getMonth() + 1}/${date.getDate()}/${year}`;
}


export default InvoiceHistory;
