import React from 'react';
import PageFooter, { PAGE_STYLE_FULLSCREEN } from '../PageFooter/PageFooter';
import PageHeader from '../PageHeader/PageHeader';

import './Page.scss';

interface PageProps {
  className?:string;
  noBG?:boolean,
  title?:string; // For Mobile
  onReturnClick?:Function; // For Mobile
  onCloseClick?:Function; // For Mobile
}

const Page:React.FunctionComponent<PageProps> = props => {
  const className = ['Page'];
  props.className && className.push(props.className);
  props.noBG && className.push('no-bg');

  return (
    <div className={ className.join(' ') } >
      <PageHeader title={props.title} onReturnClick={props.onReturnClick} onCloseClick={props.onCloseClick}/>
      <div className="page-offset">
        <div className="page-content">
          { props.children }
        </div>
      </div>
      
      <PageFooter pageStyle={ PAGE_STYLE_FULLSCREEN } />
    </div>
  );
}

export default Page;
