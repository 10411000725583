import { Alert, Body, Display, Spinner } from '@walmart-web/livingdesign-components';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../App/App';
import security from '../../lib/security';
import { REASON_SESSION_EXPIRED } from '../../lib/ServerApi';
import Page from '../Page/Page';

import './Logout.scss';

interface LogoutProps {
  expired?: boolean,
}

export const LOGOUT_PATH = '/logout';

const Logout:React.FunctionComponent<LogoutProps> = props => {
  const params = useParams();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const [processing, setProcessing] = useState(false);


  useEffect(() => {
    if (auth.user && auth.user.dasher_user_id) {
      setProcessing(true);
      security.logoutUser(auth.user, () => {
        setProcessing(false);
      }, params.reason || 'logout-page', props.expired || false);
    }
  }, [auth.user, params.reason, props.expired]);


  return (
    <Page className="Logout">
      { !processing &&
        <Alert variant="success" actionButtonProps={{
          children: "Return to login",
          onClick: () => navigate('/login')
        }}>
          {
            params.reason === REASON_SESSION_EXPIRED ?
            <Body>Your login session has expired.</Body> : 
            <Body>You've been successfully logged out.</Body>
          }
        </Alert>
      }
      { processing && <>
        <Display size="small">Logging you out</Display>
        <Spinner />
      </>}
    </Page>
  );
}

export default Logout;
