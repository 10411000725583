import React, { useState } from 'react'
import {
    TextField,
    Heading,
    Caption,
    Body
} from "@walmart-web/livingdesign-components";

import './BusinessAddress.scss'

export type BusinessAddressChangeEvent = {
    line1: string,
    line2: string,
    city: string,
    state: string,
    postal_code: string,
}

interface BusinessAddressProps {
    line1: string,
    line2: string,
    city: string,
    state: string,
    postal_code: string,
    onChange: Function,
}

const BusinessAddress: React.FunctionComponent<BusinessAddressProps> = props => {

    const { line1, line2, city, state, postal_code, onChange } = props;

    const handleBusinessAddressChange = (field: string, value: string) => {
        const current: BusinessAddressChangeEvent = {
            line1,
            line2,
            city,
            state,
            postal_code,
        };
        const change: BusinessAddressChangeEvent = {...current, [field]: value};
        onChange(change);
    }

    return (
        <div className='BusinessAddress'>
            <Heading
                as="h1"
                size="large"
                weight={400}
            >
                Business address
                <Body as="p" size="small" weight={400}>
                    Use the address attached to your tax returns
                </Body>
            </Heading>
            <div className="textbox-wrap">
                <TextField
                    className="text-field"
                    label="Address line 1"
                    value={line1}
                    onChange={e => handleBusinessAddressChange("line1", e.target.value)}
                    type="text"
                />
                <TextField
                    className="text-field"
                    label="Address line 2 (optional)"
                    value={line2}
                    onChange={e => handleBusinessAddressChange("line2", e.target.value)}
                    type="text"
                />
            </div>
            <div className="text-field">
                <TextField
                    label="City"
                    value={city}
                    onChange={e => handleBusinessAddressChange("city", e.target.value)}
                    type="text"
                />
                <TextField
                    label="State"
                    value={state}
                    onChange={e => handleBusinessAddressChange("state", e.target.value)}
                    type="text"
                />
                <TextField
                    label="ZIP code"
                    value={postal_code}
                    onChange={e => handleBusinessAddressChange("postal_code", e.target.value)}
                    type="text"
                />
            </div> 
        </div>
    )
}

export default BusinessAddress;