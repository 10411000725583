import loginImage from '../../../images/login_image.svg';
import React from 'react';

import './LoginImage.scss';

interface LoginImageProps {
  hidden?:boolean
}

const LoginImage:React.FunctionComponent<LoginImageProps> = props => {

  return (
    <img className="login-image" alt="login illustration" src={ loginImage } hidden={props.hidden}/>
  );
}

export default LoginImage;
