import React, { useState, useEffect } from 'react';

import './OTPInput.scss'


const OTP_INPUT_LENGTH = 6;

interface OTPInputProps {
  onOTPSubmit:(otp:string) => void,
  overridenValue?:string
}

const OTPInput:React.FunctionComponent<OTPInputProps> = props => {
  const [otpValue, setOtpValue] = useState<string>('');
  const [otpValueList, setOtpValueList] = useState<Array<string>>(['', '', '', '', '', '']);
  
  useEffect(() => {
    onChange(props.overridenValue || '')
  }, [props.overridenValue]);
  
  const onChange = (value:string) => {
    value = value ? value.replace(/\D/g,'') : '';
    const otpValues = [...otpValueList];
    
    for (let i = 0; i < OTP_INPUT_LENGTH; i++) {
      let tmp:string = '';
      if (i < value.length) {
        tmp = value.charAt(i);
      }
      otpValues[i] = tmp;
    }
    
    setOtpValue(value);
    setOtpValueList(otpValues);
    
    if (otpValues.every(val => val)) {
      props.onOTPSubmit(value);
    }
  }
  
  return (
    <div className="OTPInput">
      <input
        className="otp-input"
        onChange={e => onChange(e.target.value)}
        type="number"
        maxLength={OTP_INPUT_LENGTH}
        value={otpValue}/>
      <div className="otp-text-fields">
        { otpValueList.map((val, idx) => <div className="card" key={idx}>{val || ''}</div>) }
      </div>
    </div>
    );
  }
  
  export default OTPInput;
  