import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App/App';
import "@livingdesign/bogle/dist/Bogle.css";
import "@walmart-web/livingdesign-components/index.esm.css";
import { GoogleOAuthProvider } from '@react-oauth/google';
import {LivingDesignProvider, SnackbarProvider} from "@walmart-web/livingdesign-components";

const root = ReactDOM.createRoot(
  document.getElementById('root')
);
root.render(
  <React.StrictMode>
    <LivingDesignProvider>
      <SnackbarProvider>
        <GoogleOAuthProvider clientId={ window.env.REACT_APP_GOOGLE_ID }>
          <App />
        </GoogleOAuthProvider>
      </SnackbarProvider>
    </LivingDesignProvider>
  </React.StrictMode>
);
