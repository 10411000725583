import { Alert, Heading, Spinner } from '@walmart-web/livingdesign-components';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { AuthContext, BrandContext } from '../../App/App';
import ServerApi from '../../lib/ServerApi';
import { HOMEPAGE_DASHBOARD_CONFIG_API_ERROR, LANDING_HEADER } from '../../lib/string';
import InvoiceHome from '../InvoiceHome/InvoiceHome';
import LandingPage from '../LandingPage/LandingPage';
import PayoutHome from '../PayoutHome/PayoutHome';
import PayoutPage from '../PayoutPage/PayoutPage';
import UpdatePayoutPage from '../UpdatePayoutPage/UpdatePayoutPage';
import DriverRoster from '../DriverRoster/DriverRoster';


import './MerchantPortal.scss';

export type RestaurantType = {
	restaurant_id: string,
	name: string,
	address: string,
  invoiceEmail: string
}


interface MerchantPortalProps {
}

// let didRedirect = false; // Only redirect to deposit setup once, the first time we load if condition is met.
export const RestaurantContext = createContext<RestaurantType>({restaurant_id:'', address:'', name: '', invoiceEmail: ''});

const MerchantPortal:React.FunctionComponent<MerchantPortalProps> = props => {
  const brand = useContext(BrandContext);
  const [loadingConfigData, setLoadingConfigData] = useState(true);
  const [federalTaxClassifications, setFederalTaxClassifications] = useState<Array<string>>([]);
  const [errorMessage, setErrorMessage] = useState("");

  const auth = useContext(AuthContext);
	const ownedRestaurants = auth.user.owned_restaurant_details;

  let restaurantList:Array<any> = [];
	if (ownedRestaurants) {
		restaurantList = Object.keys(ownedRestaurants).map((id:string) => ({
			restaurant_id:ownedRestaurants[id].restaurant_id, name: ownedRestaurants[id]
		}));
	}
  
  const params = useParams();
  const restaurant = restaurantList.find(r => r.restaurant_id === params.restId);

  function fetchPortalConfig():Promise<any> {
    return new Promise((resolve, reject) => {
      const path = `/v1/dashboard/config`;
  
      ServerApi.supportApi.get(path)
      .then(response => {
        if (!response || !response.data || !response.data.federal_tax_classifications) { 
          setErrorMessage(brand.getString(HOMEPAGE_DASHBOARD_CONFIG_API_ERROR));
          reject();
        } else {
          resolve(response.data);
        }
      })
      .catch(error => {
        setErrorMessage(brand.getString(HOMEPAGE_DASHBOARD_CONFIG_API_ERROR));
        reject();
      });
    });
  }

  //
  // Set the navigate property so clientAPI can nav
  //
	const navigate = useNavigate();
	useEffect(() => {
		ServerApi.setNavigate(navigate);
	}, [navigate]);

  //
  // Initialize Portal Config federal_tax_classifications
  //
  useEffect(() => {
    setLoadingConfigData(true);
    fetchPortalConfig()
    .then(data => {
      setFederalTaxClassifications(data.federal_tax_classifications);
      setLoadingConfigData(false);
    })
    .catch(err => {
      setErrorMessage("There was a problem loading your data. Please try logging out and back in.");
      setLoadingConfigData(false);
    });
  }, []);

  if (loadingConfigData) {
    return (<>
      <Heading as="h1" size="large">
        {brand.getString(LANDING_HEADER)}
      </Heading>
      <Spinner />
      </>);
  }

  return (
    <div className="MerchantPortal">
      { loadingConfigData && <Spinner color="gray" size="large" /> }
      { errorMessage && <Alert variant="error">{errorMessage}</Alert>}
      {restaurant && 
        <RestaurantContext.Provider value={restaurant}>
          <Routes>
            <Route path="/" element={ <LandingPage /> } />
            <Route path="/payout" element={ <PayoutHome /> } />
            <Route path="/payout-setup" element={ <PayoutPage federalTaxClassifications={ federalTaxClassifications } /> } />
            <Route path="/update-payout" element={ <UpdatePayoutPage federalTaxClassifications={ federalTaxClassifications } /> } />
            <Route path="/invoice" element={ <InvoiceHome /> } />
            <Route path="/drivers" element={ <DriverRoster /> } />
          </Routes>
        </RestaurantContext.Provider>
      }
    </div>
  );
}

export default MerchantPortal;
