import {
  Heading,
  Spinner,
  Grid,
  GridColumn,
  ErrorMessage,
  Button,
} from "@walmart-web/livingdesign-components";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios";
import { BrandContext } from "../../App/App";
import { RestaurantContext } from "../MerchantPortal/MerchantPortal";
import Page from "../Page/Page";
import Card from "../common/Card/Card";
import { BrandNames, BrandOrdersURL, config } from "../../config";
import ServerApi from "../../lib/ServerApi";
import {
  LANDING_HEADER,
  LANDING_SUBHEADER_PAYOUT,
  LANDING_SUBHEADER_CATALOG,
  LANDING_PAYOUT_TITLE,
  LANDING_PAYOUT_AVAILABLE_DESCRIPTION,
  LANDING_PAYOUT_UNAVAILABLE_DESCRIPTION,
  LANDING_INVOICE_TITLE,
  LANDING_INVOICE_AVAILABLE_DESCRIPTION,
  LANDING_INVOICE_UNAVAILABLE_DESCRIPTION,
  LANDING_CATALOG_TITLE,
  LANDING_CATALOG_AVAILABLE_DESCRIPTION,
  LANDING_CATALOG_UNAVAILABLE_DESCRIPTION,
  LANDING_ORDER_TITLE,
  LANDING_ORDER_AVAILABLE_DESCRIPTION,
  LANDING_ORDER_UNAVAILABLE_DESCRIPTION,
  LANDING_CONFIG_API_ERROR,
  LANDING_DRIVER_TITLE,
  LANDING_DRIVER_DESCRIPTION,
} from "../../lib/string";
import payoutImg from "../../images/payout.svg";
import invoiceImg from "../../images/transactions_balances.svg";
import catalogImg from "../../images/catalog.svg";
import orderManagerImg from "../../images/order_manager.svg";
import driverRosterImg from "../../images/driver_roster.svg";
import cone from "../../images/cone.svg";

import "./LandingPage.scss";

interface LandingPageProps {}

const LandingPage: React.FunctionComponent<LandingPageProps> = (props) => {
  const brand = useContext(BrandContext);
  const navigate = useNavigate();
  const [loadingStoreConfig, setLoadingStoreConfig] = useState(true);
  const [showError, setShowError] = useState(false);
  const [paymentIsEnabled, setPaymentIsEnabled] = useState(false);
  const [isInvoiceEnabled, setIsInvoiceEnabled] = useState(false);
  const [isCatalogEnabled, setIsCatalogEnabled] = useState(false);
  const [isOrderManagerEnabled, setIsOrderManagerEnabled] = useState(false);
  const [regionId, setRegionId] = useState("");
  const [isBYOD, setIsBYOD] = useState(false);

  const restaurant = useContext(RestaurantContext);

  const fetchStoreConfig = (id: string) => {
    setShowError(false);
    setLoadingStoreConfig(true);
    fetchRestaurantConfig(id, brand)
      .then((data) => {
        setPaymentIsEnabled(data.payment_setup);
        setIsInvoiceEnabled(data.invoices_generated);
        setIsCatalogEnabled(data.menu_enabled);
        setIsOrderManagerEnabled(data.has_tablet);
        setRegionId(data.region_ids[0]);
        setIsBYOD(data.byod_seller || false);
      })
      .catch((errMsg) => {
        console.error("Error loading store data.", errMsg);
        setShowError(true);
      })
      .finally(() => setLoadingStoreConfig(false));
  };

  useEffect(() => {
    fetchStoreConfig(restaurant.restaurant_id);
  }, [restaurant.restaurant_id]);

  const getLineBreak = () => {
    return (
      !paymentIsEnabled && (
        <GridColumn className="line-break" md={6} lg={8}>
          {""}
        </GridColumn>
      )
    );
  };

  const getLandingPage = () => {
    if (loadingStoreConfig) {
      return <Spinner color="gray" size="large" />;
    } else if (showError) {
      return (
        <ErrorMessage
          id="errorMessage"
          actions={
            <Button
              size="medium"
              variant="primary"
              onClick={() => {
                fetchStoreConfig(restaurant.restaurant_id);
              }}
            >
              Refresh
            </Button>
          }
          media={<img className="coneImg" alt="" src={cone} />}
          title="Sorry, something went wrong."
        >
          Please try again.
        </ErrorMessage>
      );
    } else {
      return (
        <React.Fragment>
          <Heading as="span" size="small" weight={400}>
            {!paymentIsEnabled && brand.getString(LANDING_SUBHEADER_PAYOUT)}
            {paymentIsEnabled &&
              !isCatalogEnabled &&
              brand.getString(LANDING_SUBHEADER_CATALOG)}
          </Heading>
          <Grid hasGutter className="grid">
            <GridColumn sm={12} md={6} lg={4}>
              <Card
                className="payout-card"
                title={brand.getString(LANDING_PAYOUT_TITLE)}
                imgSrc={payoutImg}
                disabled={false}
                content={
                  paymentIsEnabled
                    ? brand.getString(LANDING_PAYOUT_AVAILABLE_DESCRIPTION)
                    : brand.getString(LANDING_PAYOUT_UNAVAILABLE_DESCRIPTION)
                }
                onClick={() => navigate("payout")}
              />
            </GridColumn>
            {getLineBreak()}
            <GridColumn sm={12} md={6} lg={4}>
              <Card
                className="invoice-card"
                title={brand.getString(LANDING_INVOICE_TITLE)}
                imgSrc={invoiceImg}
                disabled={false}
                content={
                  isInvoiceEnabled
                    ? brand.getString(LANDING_INVOICE_AVAILABLE_DESCRIPTION)
                    : brand.getString(LANDING_INVOICE_UNAVAILABLE_DESCRIPTION)
                }
                onClick={() => navigate("invoice")}
              />
            </GridColumn>
            <GridColumn sm={12} md={6} lg={4}>
              <Card
                className="catalog-card"
                title={brand.getString(LANDING_CATALOG_TITLE)}
                imgSrc={catalogImg}
                disabled={!isCatalogEnabled}
                content={
                  isCatalogEnabled
                    ? brand.getString(LANDING_CATALOG_AVAILABLE_DESCRIPTION)
                    : brand.getString(LANDING_CATALOG_UNAVAILABLE_DESCRIPTION)
                }
                onClick={() => 
                  window.open(
                    `${config[brand.name.toLowerCase() as BrandNames]}?target=menu&menu_mode=view&rid=${regionId}&target_id=${restaurant.restaurant_id}`,"_blank"
                  )
                }
              />
            </GridColumn>
            <GridColumn sm={12} md={6} lg={4}>
              <Card
                className="order-manager-card"
                title={brand.getString(LANDING_ORDER_TITLE)}
                imgSrc={orderManagerImg}
                disabled={!isOrderManagerEnabled}
                content={
                  isOrderManagerEnabled
                    ? brand.getString(LANDING_ORDER_AVAILABLE_DESCRIPTION)
                    : brand.getString(LANDING_ORDER_UNAVAILABLE_DESCRIPTION)
                }
                onClick={() => 
                  window.open(`${config[(brand.name.toLowerCase() + 'OrdersUrl') as BrandOrdersURL]}`,"_blank")
                }
              />
            </GridColumn>
            { isBYOD && (
              <GridColumn sm={12} md={6} lg={4}>
                <Card
                  className="driver-manager-card"
                  title={brand.getString(LANDING_DRIVER_TITLE)}
                  imgSrc={driverRosterImg}
                  disabled={false}
                  content={brand.getString(LANDING_DRIVER_DESCRIPTION)}
                  onClick={() => navigate("drivers")}
                />
              </GridColumn>
            )}
          </Grid>
        </React.Fragment>
      );
    }
  };

  return (
    <Page noBG className="LandingPage">
      <Heading as="h1" size="large">
        {brand.getString(LANDING_HEADER)}
      </Heading>
      {getLandingPage()}
    </Page>
  );
};

function fetchRestaurantConfig(restaurantId: string, brandContext: any): Promise<any> {
  return new Promise((resolve, reject) => {
    const path = `/v1/dashboard/${restaurantId}/config`;
    const msg = brandContext.getString(LANDING_CONFIG_API_ERROR);
    ServerApi.supportApi
      .get(path)
      .then((response: AxiosResponse) => {
        if (!response || !response.data) {
          reject(msg);
        } else {
          resolve(response.data);
        }
      })
      .catch((error) => {
        console.log(error.response);
        reject(msg);
      });
  });
}

export default LandingPage;
