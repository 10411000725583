import React, { useContext } from 'react';
import { Button, Heading, IconButton } from '@walmart-web/livingdesign-components';
import { ChevronLeft, Close } from '@livingdesign/icons';


import { AuthContext, BrandContext } from '../../App/App';
import { useNavigate } from 'react-router-dom';
import { LOGOUT_PATH } from '../Logout/Logout';

import './PageHeader.scss';


interface PageHeaderProps {
  title?: string;
  onReturnClick?: Function;
  onCloseClick?: Function;
}

interface DeafultHeaderProps {
  className: string;
}

const PageHeader:React.FunctionComponent<PageHeaderProps> = props => {
  const auth = useContext(AuthContext);
  const brand = useContext(BrandContext);
  const navigate = useNavigate();
  const {title, onReturnClick, onCloseClick} = props;

  const DefaultHeader = (props: DeafultHeaderProps) => {
    return (
      <div className={props.className}>
        <img src={ brand.logo } alt="Logo" />
        {
          !!auth.user && !!auth.user.dasher_user_id &&
            <Button onClick={ e => navigate(LOGOUT_PATH) } variant="primary">Sign out</Button>
        }
    </div>
    )
  }

  // For mobile only
  const NavHeader = (props: DeafultHeaderProps) => {
    return (
      <div className={props.className}>
        {onReturnClick && <IconButton UNSAFE_className="return-button" a11yLabel="back" size="medium" onClick={() => onReturnClick()}>
          <ChevronLeft />
        </IconButton>}

       {title && <Heading UNSAFE_className="title" as="h1" size="small" weight={700}>{title}</Heading>}
        {onCloseClick && <IconButton UNSAFE_className="close-button" a11yLabel="close" size="medium" onClick={() => onCloseClick()}>
          <Close />
        </IconButton>}
      </div>
    )
  }

  return (
    <div>
      <DefaultHeader className="PageHeader main-header"/>
      {title 
        ? <NavHeader className="PageHeader mobile-header nav-header"/> 
        : <DefaultHeader className="PageHeader mobile-header"/>}
    </div>
  );
}

export default PageHeader;
