import axios from 'axios';
import { NavigateFunction } from 'react-router-dom';
import { LOGOUT_PATH } from '../components/Logout/Logout';
import { config } from '../config';
import analytics from './analytics';
import security, { ERROR_SESSION_EXPIRED } from './security';

export const REASON_SESSION_EXPIRED = 'session-expired-api-error';

const ERROR_CODE_MESSAGE: any = {
  '404': "Not found",
  '503': "Timeout",
  '401': "Access denied",
  '500': "Unknown error",
  '-3': "Offline"
}

/**
 * Wrapper for Axios with a given base path and standard error handling
 */
class API {
  private axios;
  private routerNavigator:NavigateFunction|null = null;
  private headers:any = {};

  constructor(baseURL:string) {

    this.axios = axios.create({
      timeout: 60000, // Menu manager can take a long time to load
      baseURL,
      headers: {}
    });
  }


  public setNavigate(navigate: NavigateFunction): void {
    this.routerNavigator = navigate;
  }


  public setHeader(header:string, value:any): void {
    if (value === null) {
      delete this.headers[header];
    }
    else {
      this.headers[header] = value;
    }
  }


  postQuietly(path: string, data: any, headers: any = {}): Promise<any> {
    return this.post(path, data, headers);
  }


  post(path: string, data: any, headers: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      Object.assign(headers, this.headers);

      const success = (resp: any) => {
        resolve(resp);
      };

      const failure = (err: any) => {
        this.handleError(err, reject);
      }

      this.axios.post(path, data, { headers }).then(success).catch(failure);
    });
  }

  
  put(path: string, data: any, headers: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      Object.assign(headers, this.headers);
      this.axios.put(path, data, { headers }).then(resolve).catch((err) => this.handleError(err, reject));
    });
  }

  
  delete(path: string, headers: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      Object.assign(headers, this.headers);
      this.axios.delete(path, { headers }).then(resolve).catch((err) => this.handleError(err, reject));
    });
  }


  public get(path: string, headers: any = {}, params: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {

      const success = (resp: any) => {
        resolve(resp);
      };

      const failure = (err: any) => {
        this.handleError(err, reject);
      }

      const doGET = () => {
        Object.assign(headers, this.headers); // Do this after session check in case we renewed the token

        this.axios.get(path, { headers, params }).then(success).catch(failure);
      };

      // Make sure the session isn't expiring soon
      if (security.verifySession()) {
        doGET();
      }
      else {
        const reason = 'expiring-soon';
        this.routerNavigator && this.routerNavigator(`${LOGOUT_PATH}/${reason}`);
      }
    });
  }


  private getMixpanelApiObject(url: string): any {
    try {
      const lastPathSlash = url.lastIndexOf('/');
      const queryParamIndex = url.indexOf('?', lastPathSlash);
      return {
        path: queryParamIndex > lastPathSlash ? url.substring(lastPathSlash, queryParamIndex) : url.substring(lastPathSlash),
        queryParam: queryParamIndex > 0 ? url.substring(queryParamIndex) : ''
      }
    }
    catch (err) {
      return {};
    }
  }


  //
  // Generc Axios error handler
  //
  private handleError(error: any, callback: Function) {
    console.error(error);

    // Normalize error response object to always look like Axios for simplicity
    const errorData: any = { response: { data: { error_code: 'NO SERVER ERROR CODE', message: '' } } };
    let apiObject: any = {};
    let httpErrorCode: string = errorData.error_code;

    if (error) {
      // Axios HTTP error handling
      if (error.response) {
        httpErrorCode = error.response.status || errorData.error_code;

        if (typeof error.response.data === 'object') {
          errorData.response.data.error_code = error.response.data.error_code || `HTTP ${httpErrorCode}` || '';
          errorData.response.data.message = error.response.data.message || ERROR_CODE_MESSAGE[httpErrorCode] || '';
        }
        else {
          errorData.response.data.error_code = `HTTP ${httpErrorCode}`;
          errorData.response.data.message = ERROR_CODE_MESSAGE[httpErrorCode] || '';
        }

        if (error.response.config && error.response.config.url) {
          apiObject = this.getMixpanelApiObject(error.response.config.url);
        }
      }
      else {
        // Native HTTP Plugin error handling
        httpErrorCode = error.status || errorData.error_code;

        if (error.error) {
          try {
            errorData.response.data = JSON.parse(error.error);
            errorData.response.data.error_code = errorData.response.data.error_code || `HTTP ${httpErrorCode}`;
            errorData.response.data.message = errorData.response.data.message || ERROR_CODE_MESSAGE[httpErrorCode] || '';
          } catch (err) {
            console.warn('Error parsing JSON object', error);
            errorData.response.data.error_code = `HTTP ${httpErrorCode}`;
            errorData.response.data.message = ERROR_CODE_MESSAGE[httpErrorCode] || '';
          }
        }
        else {
          errorData.response.data.error_code = `HTTP ${httpErrorCode}`;
          errorData.response.data.message = ERROR_CODE_MESSAGE[httpErrorCode] || '';
        }

        if (error.url) {
          apiObject = this.getMixpanelApiObject(error.url);
        }
      }
    }

    analytics.track(analytics.TRACK_API_ERROR, {
      errorCode: errorData.response.data.error_code || 'ERROR HAS NO CODE',
      errorMessage: errorData.response.data.message || '',
      apiPath: apiObject.path || '',
      queryParam: apiObject.queryParam || '',
    });

    // Log the user out if their session is expired
    if (errorData.response.data.error_code === ERROR_SESSION_EXPIRED) {
      const reason = REASON_SESSION_EXPIRED;
      window.location.hash = `${LOGOUT_PATH}/${reason}/expired`;
    }

    callback(errorData);
  }
}


/**
 * One class for client and support
 */
export class ServerApi {

  // Public constants
  public HEADER_USER_TOKEN: string = 'dasher_user_token';
  public HEADER_DEVICE_ID: string = 'device_id';
  public HEADER_SESSION_TOKEN: string = 'session_token';

  public clientApi;
  public supportApi;

  constructor(){
    this.clientApi = new API('clientapi');
    this.supportApi = new API('supportapi');
  }


  public setHeader(key:string, value:any):void {
    this.clientApi.setHeader(key, value);
    this.supportApi.setHeader(key, value);
  }


  public setNavigate(navgate:NavigateFunction):void {
    this.clientApi.setNavigate(navgate);
    this.supportApi.setNavigate(navgate);
  }
}

export default new ServerApi();