import { Divider } from '@walmart-web/livingdesign-components';
import React from 'react';
import Footer from '../common/Footer/Footer';

import './ModalFooter.scss';

interface ModalFooterProps {
}

const ModalFooter:React.FunctionComponent<ModalFooterProps> = props => {
  return (
    <div className="ModalFooter">
      <Footer/>
    </div>
  );
}

export default ModalFooter;
