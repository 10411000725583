import React, { useState, useEffect } from 'react';
import Axios, { AxiosResponse } from 'axios';
import { AppConfig } from '../../../App/App';
import { config } from '../../../config';
import ServerApi from '../../../lib/ServerApi';

import {
    TextField,
    Heading,
    Caption,
    Select,
    Body
} from "@walmart-web/livingdesign-components";

import './TaxDetails.scss'


export type TaxDetailsChangeEvent = {
    tax_id_number: string,
    business_legal_name: string,
    federal_tax_classification: string,
}

interface TaxDetailsProps {
    onChange: Function,
    federalTaxClassificationsList: Array<string>
    tax_id_number: string,
    business_legal_name: string,
    federal_tax_classification: string
}

const TaxDetails: React.FunctionComponent<TaxDetailsProps> = props => {
    const [taxIDError, setTaxIDError] = useState(false);

    const { tax_id_number, business_legal_name, federal_tax_classification, onChange, federalTaxClassificationsList } = props;

    const renderFederalTaxClassificationDropdown = (federalTaxClassifications: Array<string>) => {
        const fedTaxOptions: any = [];

        fedTaxOptions.push(<option key='hc' value=''>Federal Tax Classification</option>);

        federalTaxClassifications.forEach((value: string, idx: any) => {
            fedTaxOptions.push(<option key={idx} value={value}>{value}</option>);
        });

        return (
            <Select
                className="text-field"
                label=""
                onChange={e => handleTaxDetailsChange("federal_tax_classification", e.target.value)}>
                {fedTaxOptions}
            </Select>
        );
    }

    const handleTaxDetailsChange = (field: string, value: string) => {
        const current: TaxDetailsChangeEvent = {
            tax_id_number,
            business_legal_name,
            federal_tax_classification,
        };
        const change: TaxDetailsChangeEvent = {...current, [field]:value};
        onChange(change);
    }

    const handletaxIdNumberChange = (taxIdNumber: string) => {
        if (taxIdNumber.length === 0 || taxIdNumber.length === 9) {
            setTaxIDError(false);
        } else {
            setTaxIDError(true);
        }

        handleTaxDetailsChange("tax_id_number", taxIdNumber);
    }

    return (
        <div className='TaxDetails'>
            <Heading
                as="h1"
                size="large"
                weight={400}
            >
                Tax details
                <Body as="p" size="small" weight={400}>
                    You can find this information on your latest tax return.
                </Body>
            </Heading>
            <div className="textbox-wrap">
                <TextField
                    className="text-field"
                    label="Tax ID number"
                    error={taxIDError ? "Invalid Tax ID Number" : ""}
                    value={tax_id_number}
                    onChange={e => handletaxIdNumberChange(e.target.value)}
                    type="text"
                />
                <TextField
                    className="text-field"
                    label="Business legal name"
                    value={business_legal_name}
                    onChange={e => handleTaxDetailsChange("business_legal_name", e.target.value)}
                    type="text"
                />
            </div>
            {renderFederalTaxClassificationDropdown(federalTaxClassificationsList)}
        </div>
    );
}

export default TaxDetails;