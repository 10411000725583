import React, { useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { BrandContext } from '../../App/App';

import TaxDetails, { TaxDetailsChangeEvent } from '../PayoutPage/TaxDetails/TaxDetails';
import BusinessAddress, { BusinessAddressChangeEvent } from '../PayoutPage/BusinessAddress/BusinessAddress';
import ServerApi from '../../lib/ServerApi';
import { RestaurantContext } from '../MerchantPortal/MerchantPortal';
import Page from '../Page/Page';
import {
  Divider,
  Heading,
  Checkbox,
  ButtonGroup,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Body,
  useSnackbar,
  Alert,
  Spinner,
} from "@walmart-web/livingdesign-components";
import { LANDING_HEADER, PAYOUT_UPDATE_PAYMENT_ERROR, PAYOUT_UPDATE_PAYMENT_SUCCESS } from '../../lib/string';

import './UpdatePayoutPage.scss';

export const PAYOUT_UPDATE = {
  SUB_TITLE: "Update payout method",
  TAX_DETAILS_SUB_TITLE: "Update tax details",
  BUSINESS_ADDRESS_DETAILS_SUB_TITLE: "Update business address details"
}


interface UpdatePayoutPageProps {
  federalTaxClassifications: Array<string>
}


export type PayoutUpdateForm = {
  tax_id_number: string,
  business_legal_name: string,
  federal_tax_classification: string,
  line1: string,
  line2: string,
  city: string,
  state: string,
  postal_code: string,
  country: string,
  penalty_for_perjury_acknowledged: boolean
};

type LocationState = {needTaxDetails: boolean, needBusinessAddressDetails: boolean};

const UpdatePayoutPage: React.FunctionComponent<UpdatePayoutPageProps> = props => {
  const brand = useContext(BrandContext);
  const restaurant = useContext(RestaurantContext);
  const navigate = useNavigate();
  const {addSnack} = useSnackbar();
  const {state} = useLocation();

  const [data, setData] = useState(resetForm());
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const {federalTaxClassifications} = props;

  let needTaxDetails: boolean = true;
  let needBusinessAddressDetails: boolean = true;
  if (state) {
    ({needTaxDetails, needBusinessAddressDetails} = state as LocationState);
  }

  let formValid = false;

  const navigateToPayoutHome = () => {
    navigate(`/${restaurant.restaurant_id}/payout`);
  }
  
  const getHeading = (): string => {
    if (needTaxDetails && needBusinessAddressDetails) {
      return PAYOUT_UPDATE.SUB_TITLE;
    } else if (needTaxDetails) {
      return PAYOUT_UPDATE.TAX_DETAILS_SUB_TITLE;
    } else {
      return PAYOUT_UPDATE.BUSINESS_ADDRESS_DETAILS_SUB_TITLE;
    }
  }

  const handleSave = () => {
    // prevent from executing the handleSave function if saving is in progress
    if (saving) {
      return;
    }
    
    const taxFormData = needTaxDetails ? {
      tax_id_number: data.tax_id_number,
      company_name: data.business_legal_name,
      federal_tax_classification: data.federal_tax_classification,
    } : {};

    const businessAddressFormData = needBusinessAddressDetails ? {
      line1: data.line1,
      line2: data.line2 || '',
      city: data.city,
      state: data.state,
      postal_code: data.postal_code,
      country: data.country,
    } : {};

    const checkBoxFormData = {
      penalty_for_perjury_acknowledged: data.penalty_for_perjury_acknowledged
    };

    const managedAccountForm:any = Object.assign({}, taxFormData, businessAddressFormData, checkBoxFormData);

    setSaving(true);
    updateAccount(restaurant.restaurant_id, managedAccountForm)
    .then(() => {addSnack({message: brand.getString(PAYOUT_UPDATE_PAYMENT_SUCCESS)})})
    .then(()=> {
      setSaving(false);
      navigateToPayoutHome();
    })
    .catch((err => {
      setErrorMessage(brand.getString(PAYOUT_UPDATE_PAYMENT_ERROR));
      setSaving(false);
    }));
  }

  const handleChange = (change: TaxDetailsChangeEvent | BusinessAddressChangeEvent) => {
    getEntries(change).forEach(
      ([key, value]) => {
        updateForm(key, value)
      }
    );
  }

  const updateForm = (key: string, value: string | boolean) => {

    setData((theForm: any) => ({ ...theForm, [key]: value }));
  }
  
  const evaluateFormValidity = () => {
    const isTaxValid = needTaxDetails ? (!!data.tax_id_number && data.tax_id_number.length === 9 && !!data.business_legal_name 
      && !!data.federal_tax_classification) : true;
    const isBusinessAddressValid = needBusinessAddressDetails ? (!!data.line1 && !!data.city && !!data.state 
      && !!data.postal_code && !!data.country) : true;

    const isFormValid = isTaxValid && isBusinessAddressValid && data.penalty_for_perjury_acknowledged;

    formValid = isFormValid;
  }

  useEffect(() => {
    if (!state) {
      navigateToPayoutHome();
    }
  }, [state]);

  evaluateFormValidity();

  return (
    <Page className="UpdatePayoutPage" title={getHeading()} onReturnClick={navigateToPayoutHome}>
      <Breadcrumb className="breadcrumb">
        <BreadcrumbItem href={getParentURL()}>
          { brand.getString(LANDING_HEADER) }
        </BreadcrumbItem>
        <BreadcrumbItem href={getParentURL() + "update-payout"} isCurrent>
          { getHeading() }
        </BreadcrumbItem>
      </Breadcrumb>
      { errorMessage && <Alert variant="error">{errorMessage}</Alert>}
      <div className="payout">
        <Heading className="heading" as="h1" size="large" weight={700}>{getHeading()}</Heading>
        <div className="payout-form">
          {needTaxDetails && <TaxDetails
            onChange={handleChange}
            tax_id_number={data.tax_id_number}
            business_legal_name={data.business_legal_name}
            federal_tax_classification={data.federal_tax_classification}
            federalTaxClassificationsList={federalTaxClassifications}
          />}
          {(needTaxDetails && needBusinessAddressDetails) && <Divider />}
          {needBusinessAddressDetails && <BusinessAddress
            onChange={handleChange}
            line1={data.line1}
            line2={data.line2}
            city={data.city}
            state={data.state}
            postal_code={data.postal_code}
          />}
          <div>
            <Checkbox
              label="Under penalties of perjury, I certify that:"
              checked={data.penalty_for_perjury_acknowledged}
              onChange={e => updateForm('penalty_for_perjury_acknowledged', e.target.checked)} />
            <Body as="p" size="small" weight={data.penalty_for_perjury_acknowledged ? 700 : 400}>
              <ol className="checkbox-list">
                <li>The number shown on this form is my current taxpayer identification number; and</li>
                <li>
                  I am not subject to backup withholding because: (a) I am exempt from back withholding, or (b) I have not been notified by the Internal Revenue Service (IRS) that I am subject to back withholding as a result of a failure to report all interest or dividends, or (c) the IRS has notified me that I am no longer subject to backup withholding: and
                </li>
                <li>I am a U.S. citizen or other U.S. person (including an individual who is a U.S. citizen or U.S. resident alien: a partnership, corporation, company or association created or organized in the United States or under the laws of the United States; an estate (other than a foreign estate); or a domestic trust (as defined in Regulations section 301.7701-7).</li>
              </ol>
            </Body>
          </div>
          <ButtonGroup className="button-group">
            <Button className="cancel-button" size="medium" variant="tertiary" onClick={() => navigateToPayoutHome()}>
              Cancel
            </Button>
            {formValid
              ? <Button className="submit-button" size="medium" variant="primary" onClick={handleSave}>Submit</Button>
              : <Button className="submit-button" disabled size="medium" variant="primary">Submit</Button>}
          </ButtonGroup>
          {saving && <Spinner />}
        </div>
      </div>
    </Page>
  );
}

function getEntries(obj:any) {
  return Object.keys(obj).map(key => [key, obj[key]]);
}

function getParentURL(): string {
  const baseURL = window.location.href;
  return baseURL.slice(0, baseURL.lastIndexOf('/') + 1)
}

function resetForm(): PayoutUpdateForm {
  return {
    business_legal_name: '',
    tax_id_number: '',
    federal_tax_classification: '',
    line1: '',
    line2: '',
    city: '',
    state: '',
    postal_code: '',
    country: 'US',
    penalty_for_perjury_acknowledged: false
  };
}

function updateAccount(restId:string, formData:any):Promise<void> {
  return new Promise((resolve, reject) => {
    ServerApi.clientApi.put(`/v2/payment/managedAccount?restaurantId=${restId}`, formData)
    .then((response:AxiosResponse) => {
      if (response && response.status === 200) {
        resolve();
      }
      else {
        reject('Server response error');
      }
    })
    .catch(reject);
  });
}

export default UpdatePayoutPage;
