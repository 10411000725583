import React from 'react';
import Footer from '../common/Footer/Footer';
import { PORTRAIT_VIEW } from '../../App/App';

import './PageFooter.scss';


export const PAGE_STYLE_FULLSCREEN = 'fullscreen';
export const PAGE_STYLE_INSET = 'inset';

interface PageFooterProps {
  pageStyle?: 'fullscreen' | 'inset'
}

const PageFooter:React.FunctionComponent<PageFooterProps> = props => {

  return (
    <div className={ `PageFooter page-#{pageStyle}` }>
      {
        !PORTRAIT_VIEW && 
        <div className="copyright">
          &copy;2022 Walmart. All Rights Reserved.
        </div>
      }
      <Footer />
      {
        PORTRAIT_VIEW && 
        <div className="copyright">
          &copy;2022 Walmart. All Rights Reserved.
        </div>
      }
    </div>
  );
}

export default PageFooter;
