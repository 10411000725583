import React from "react";
import { IconButton } from "@walmart-web/livingdesign-components";
import { ChevronRight } from "@livingdesign/icons";

import "./Card.scss";

interface CardProps {
  className: string;
  title: string;
  imgSrc: string;
  disabled: boolean;
  content: string;
  onClick: (event: any) => void;
}

interface CardClassesProps {
  className: string;
  disabled: boolean;
}

const Card: React.FunctionComponent<CardProps> = (props) => {
  const { className, title, imgSrc, disabled, content, onClick } = props;

  const getCardClasses = ({ className, disabled }: CardClassesProps) => {
    const classes = ["Card", className];
    if (disabled) {
      classes.push("disabled");
    }
    return classes.join(" ");
  };

  return (
    <div
      className={getCardClasses({
        className,
        disabled,
      })}
      onClick={(e: any) => !disabled && onClick(e)}
    >
      <div className="cardMedia">
        <img className="mediaImg" alt={title} src={imgSrc} />
      </div>
      <div className="flex">
        <div className="column">
          <div className="cardHeader">
            <div className="leadingIcon">
              <img className="iconImg" alt={title} src={imgSrc} />
            </div>
            <div className="title">{title}</div>
          </div>
          <div className="cardContent">{content}</div>
        </div>
        <div className="cardActions">
          {!disabled && (
            <IconButton size="medium" a11yLabel={title}>
              <ChevronRight />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
