import React, { useContext, useState } from 'react';
import { AxiosResponse } from 'axios';
import ServerApi from '../../lib/ServerApi';
import { BrandContext } from '../../App/App';
import { RestaurantContext } from '../MerchantPortal/MerchantPortal';
import { Driver } from './DriverRoster';
import { DRIVER_REMOVE_ERROR } from '../../lib/string';
import {
  Alert,
  Button,
  Modal,
  ButtonGroup,
  Body,
  Spinner,
} from "@walmart-web/livingdesign-components";


interface RemoveDriverModalProps {
  sellerId: string
  driver: Driver
  onClose:() => void
  onSuccess:() => void
}

const RemoveDriverModal: React.FunctionComponent<RemoveDriverModalProps> = props => {
  const brand = useContext(BrandContext);
  const restaurant = useContext(RestaurantContext);

  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');


  function removeDriver(driverId:string) {
    setSaving(true);
    deleteDriver(restaurant.restaurant_id, driverId)
    .then(props.onSuccess)
    .catch(err => {
      setErrorMessage(brand.getString(DRIVER_REMOVE_ERROR));
    })
    .finally(() => {
      setSaving(false);
    });
  }

  return (
    <Modal
      isOpen={true}
      onClose={props.onClose}
      size="small"
      title="Remove driver"
      actions={(
        <ButtonGroup>
          <Button size='large' variant='tertiary' onClick={props.onClose}>
            Cancel
          </Button>
          <Button size='large' variant='primary' disabled={saving} onClick={() => removeDriver(props.driver.driver_id) }>
            { saving ? <Spinner /> : 'Remove' }
          </Button>
        </ButtonGroup>
      )}>
        { errorMessage && <Alert variant="error">{ errorMessage }</Alert>}
        <Body>Are you sure you want to remove driver "{props.driver.first_name} {props.driver.last_name}"</Body>
    </Modal>
  )
}


function deleteDriver(sellerId:string, driverId:string):Promise<void> {
  return new Promise((resolve, reject) => {
    ServerApi.supportApi.delete(`/v1/drivers/${sellerId}/${driverId}`)
    .then((response:AxiosResponse) => {
      if (response && response.status === 200) {
        resolve();
      }
      else {
        reject();
      }
    })
    .catch(reject);
  });
}

export default RemoveDriverModal