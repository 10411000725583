import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import {  AuthContext, BrandContext } from '../../App/App';
import { CreateTokenBankAccountData, loadStripe } from '@stripe/stripe-js';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { config } from '../../config';

import BankingInfo, { BankingInfoChangeEvent } from './BankingInfo/BankingInfo';
import TaxDetails, { TaxDetailsChangeEvent } from './TaxDetails/TaxDetails';
import BusinessAddress, { BusinessAddressChangeEvent } from './BusinessAddress/BusinessAddress';
import ServerApi from '../../lib/ServerApi';
import { RestaurantContext } from '../MerchantPortal/MerchantPortal';
import Page from '../Page/Page';
import {
  Divider,
  Heading,
  Checkbox,
  ButtonGroup,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Body,
  useSnackbar,
  Alert,
  Spinner,
} from "@walmart-web/livingdesign-components";
import { PAYOUT_CREATE_PAYMENT_ERROR, PAYOUT_CREATE_PAYMENT_SUCCESS, PAYOUT_SUB_TITLE, LANDING_HEADER } from '../../lib/string';

import './PayoutPage.scss';

interface PayoutPageProps {
  federalTaxClassifications: Array<string>
}

export type PayoutForm = {
  first_name: string,
  last_name: string,
  email: string,
  routing_number: string,
  account_number: string,
  tax_id_number: string,
  business_legal_name: string,
  federal_tax_classification: string,
  line1: string,
  line2: string,
  city: string,
  state: string,
  postal_code: string,
  country: string,
  penalty_for_perjury_acknowledged: boolean
};

const PayoutPage: React.FunctionComponent<PayoutPageProps> = props => {
  const brand = useContext(BrandContext);
  const restaurant = useContext(RestaurantContext);
  const navigate = useNavigate();
  const {addSnack} = useSnackbar();

  const [data, setData] = useState(resetForm(restaurant.invoiceEmail));
  const [checkBoxTick, setCheckBoxTick] = useState(false);
  const [creating, setCreating] = useState(false);
  const [stripePromise, setStripePromise] = useState<Promise<any> | null>(null);
  const [errorMessage, setErrorMessage] = useState("");

  const routingNumberField = "routing_number";
  const accountNumberField = "account_number";
  const {federalTaxClassifications} = props;

  let formValid = false;

  // const stripe = useStripe();

  // Initialize Stripe
  useEffect(() => {
    //@ts-ignore
    setStripePromise( loadStripe(config.stripeKey) );
  }, []);

  let stripe: any;
  const auth = useContext(AuthContext);

  stripePromise?.then((stripeObject) => {
    stripe = stripeObject;
  })

  const navigateToMerchantPortal = () => {
    navigate(`/${restaurant.restaurant_id}`);
  }

  const createBankToken = (): Promise<any> => {
    // Stripe elements
    if (!stripe) {
      // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
      return Promise.reject();
    }

    const formData: CreateTokenBankAccountData = {
      country: 'US',
      currency: 'usd',
      routing_number: data.routing_number,
      account_number: data.account_number,
      account_holder_name: `${data.first_name} ${data.last_name}`,
      account_holder_type: 'individual',
    };
    return stripe.createToken('bank_account', formData);
  }

  const handleSubmit = () => {
    const formData = {
      dasher_user_id: auth.user.dasher_user_id,
      token: {},
      default: true,
      email: data.email || '',
      tos_accepted: true,
      instrument_type: 'bank_account',
      legal_name: `${data.first_name} ${data.last_name}`,
      first_name: data.first_name,
      last_name: data.last_name,
      verification_code: '',
      tax_id_number: data.tax_id_number,
      company_name: data.business_legal_name,
      line1: data.line1,
      line2: data.line2 || '',
      city: data.city,
      state: data.state,
      postal_code: data.postal_code,
      country: data.country,
      federal_tax_classification: data.federal_tax_classification,
      penalty_for_perjury_acknowledged: data.penalty_for_perjury_acknowledged
    };
    setCreating(true);
    createBankToken()
      .then(token => formData.token = token.token.id)
      .then(() => createDepositInstruments(restaurant.restaurant_id, formData))
      .then(() => addSnack({message: brand.getString(PAYOUT_CREATE_PAYMENT_SUCCESS)}))
      .then(()=> {
        setCreating(false);
        navigate(`/${restaurant.restaurant_id}/payout`);
      })
      .catch(err => {
        setErrorMessage(brand.getString(PAYOUT_CREATE_PAYMENT_ERROR));
        setCreating(false);
      });
  }

  const getEntries = (obj: any) => Object.keys(obj).map(key => [key, obj[key]]);

  const handleChange = (change: BankingInfoChangeEvent | TaxDetailsChangeEvent | BusinessAddressChangeEvent) => {
    getEntries(change).forEach(
      ([key, value]) => {
        if (key === routingNumberField) {
          updateForm(routingNumberField, cleanedRoutingNumber(value));
        }
        else if (key === accountNumberField) {
          updateForm(accountNumberField, numericOnly(value));
        }
        else {
          updateForm(key, value)
        }
      }
    );
  }

  const updateCheckBoxInForm = (key: 'penalty_for_perjury_acknowledged', value: boolean) => {
    value ? setCheckBoxTick(true) : setCheckBoxTick(false);
    updateForm('penalty_for_perjury_acknowledged', value);
  }

  const updateForm = (key: string, value: string | boolean) => {

    setData((theForm: any) => ({ ...theForm, [key]: value }));
  }
  
  const evaluateFormValidity = () => {
    const validRoutingNumber = data.routing_number.match(/[0-9]{9}/) !== null;
    const validAccountNumber = data.account_number.match(/[0-9]/) !== null;
    const validTaxId = data.tax_id_number.length === 9;

    const isFormValid = !!data.first_name && !!data.last_name && validAccountNumber
      && validRoutingNumber && validTaxId && !!data.business_legal_name
      && !!data.federal_tax_classification && !!data.line1 && !!data.city && !!data.state
      && !!data.postal_code && !!data.country && data.penalty_for_perjury_acknowledged;

    formValid = isFormValid;
  }

  const getParentURL = () => {
    const baseURL = window.location.href;
    return baseURL.slice(0, baseURL.lastIndexOf('/') + 1)
  }

  evaluateFormValidity();

  return (
    <Page className="PayoutPage" title={ brand.getString(PAYOUT_SUB_TITLE) } onReturnClick={navigateToMerchantPortal}>
      <Breadcrumb className="breadcrumb">
        <BreadcrumbItem href={getParentURL()}>
          { brand.getString(LANDING_HEADER) }
        </BreadcrumbItem>
        <BreadcrumbItem href={getParentURL() + "payout-setup"} isCurrent>
          { brand.getString(PAYOUT_SUB_TITLE) }
        </BreadcrumbItem>
      </Breadcrumb>
      { errorMessage && <Alert variant="error">{errorMessage}</Alert>}
      <div className="payout">
        <Heading className="heading" as="h1" size="large" weight={700}>Set up payout method</Heading>
        <div className="payout-form">
          <BankingInfo
            onChange={handleChange}
            first_name={data.first_name}
            last_name={data.last_name}
            routing_number={data.routing_number}
            account_number={data.account_number}
          />
          <Divider />
          <TaxDetails
            onChange={handleChange}
            tax_id_number={data.tax_id_number}
            business_legal_name={data.business_legal_name}
            federal_tax_classification={data.federal_tax_classification}
            federalTaxClassificationsList={federalTaxClassifications}
          />
          <Divider />
          <BusinessAddress
            onChange={handleChange}
            line1={data.line1}
            line2={data.line2}
            city={data.city}
            state={data.state}
            postal_code={data.postal_code}
          />
          <div>
            <Checkbox
              label="Under penalties of perjury, I certify that:"
              checked={checkBoxTick}
              onChange={e => updateCheckBoxInForm('penalty_for_perjury_acknowledged', e.target.checked)} />
            <Body as="p" size="small" weight={checkBoxTick ? 700 : 400}>
              <ol className="checkbox-list">
                <li>The number shown on this form is my current taxpayer identification number; and</li>
                <li>
                  I am not subject to backup withholding because: (a) I am exempt from back withholding, or (b) I have not been notified by the Internal Revenue Service (IRS) that I am subject to back withholding as a result of a failure to report all interest or dividends, or (c) the IRS has notified me that I am no longer subject to backup withholding: and
                </li>
                <li>I am a U.S. citizen or other U.S. person (including an individual who is a U.S. citizen or U.S. resident alien: a partnership, corporation, company or association created or organized in the United States or under the laws of the United States; an estate (other than a foreign estate); or a domestic trust (as defined in Regulations section 301.7701-7).</li>
              </ol>
            </Body>
          </div>
          <ButtonGroup className="button-group">
            <Button className="cancel-button" size="large" variant="tertiary" onClick={navigateToMerchantPortal}>
              Cancel
            </Button>
            {formValid
              ? <Button className="submit-button" size="large" variant="primary" onClick={handleSubmit}>Submit</Button>
              : <Button className="submit-button" disabled size="large" variant="primary">Submit</Button>}
          </ButtonGroup>
          {creating && <Spinner />}
        </div>
      </div>
    </Page>
  );
}

function resetForm(defaultEmail?: string): PayoutForm {
  return {
    first_name: '',
    last_name: '',
    email: defaultEmail || '',
    routing_number: '',
    account_number: '',
    business_legal_name: '',
    tax_id_number: '',
    federal_tax_classification: '',
    line1: '',
    line2: '',
    city: '',
    state: '',
    postal_code: '',
    country: 'US',
    penalty_for_perjury_acknowledged: false
  };
}


function numericOnly(val: string): string {
  return val.replace(/\D/g, '');
}

function cleanedRoutingNumber(routing: string): string {
  return numericOnly(routing).slice(0, 9);
}

function createDepositInstruments(restId: string, data: any): Promise<any> {
  return new Promise((resolve, reject) => {
    ServerApi.clientApi.post(`/v2/payment/depositInstruments?restaurantId=${restId}`, data)
      .then((response: AxiosResponse) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data);
        }
        else {
          reject('Server response error');
        }
      })
      .catch(reject);
  });
}

export default PayoutPage;
