import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Heading, Body, Radio, Button, Caption, Alert, Skeleton, Spinner } from '@walmart-web/livingdesign-components';
import { AuthContext, BrandContext, PORTRAIT_VIEW } from '../../App/App';
import security from '../../lib/security';
import ServerApi from '../../lib/ServerApi';
import LoginForm from '../Login/LoginForm/LoginForm';
import OTPForm from './OTPForm/OTPForm';
import LoginImage from '../common/LoginImage/LoginImage';
import { ONBOARDING_GENERAL_ERROR, ONBOARDING_NO_CONTACT_OPTIONS, ONBOARDING_SMS_CONSENT_MSG, ONBOARDING_SUB_TITLE, ONBOARDING_TITLE } from '../../lib/string';

import './Onboarding.scss';

const CONTACT_CHANNEL_OPTIONS = {
  SMS: 'sms',
  EMAIL: 'email'
};

interface OnboardingProps {
}

const Onboarding:React.FunctionComponent<OnboardingProps> = props => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const brand = useContext(BrandContext);
  
  const [appLoading, setAppLoading] = useState<boolean>(false);
  const [sendOtpLoading, setsendOtpLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [selectedContactChannel, setSelectedContactChannel] = useState<string>(CONTACT_CHANNEL_OPTIONS.EMAIL);
  const [showOTPForm, setShowOTPForm] = useState<boolean>(false);
  const [appErrorMsg, setAppErrorMsg] = useState<string>('');
  const [loginErrorMsg, setLoginErrorMsg] = useState<string>('');
  const [otpErrorMsg, setOtpErrorMsg] = useState<string>('');
  const registerHashId:string = searchParams.get("register") || '';


  //
  // Navigate to root if the user is signed in
  //
  useEffect(() => {
    if (auth.user && auth.user.dasher_user_id) {
      if (security.verifySession()) {
        navigate('/');
      }
    }
  }, [auth.user]);


  useEffect(() => {
    if (registerHashId) {
      setAppErrorMsg('');
      setAppLoading(true);

      ServerApi.clientApi.get(`/v1/restaurant/contactOptions?id=${registerHashId}`)
      .then(response => {
        const data = response.data;

        if (!data || (!data.email && !data.phone_number)) {
          setAppLoading(false);
          setAppErrorMsg(brand.getString(ONBOARDING_NO_CONTACT_OPTIONS));
          return;
        }

        // if no email available then set the default as SMS
        if (!data.email) {
          setSelectedContactChannel(CONTACT_CHANNEL_OPTIONS.SMS);
        }
        
        setPhoneNumber(replaceAsterisksWithBullets(data.phone_number));
        setEmail(replaceAsterisksWithBullets(data.email));
        setAppLoading(false);
      })
      .catch(() => {
        setAppLoading(false);
        setAppErrorMsg(brand.getString(ONBOARDING_NO_CONTACT_OPTIONS));
      });
    }
  }, [registerHashId]);

  const sendOtpCode = () => {
    if (!sendOtpLoading) {
      setOtpErrorMsg('');
      setsendOtpLoading(true);

      ServerApi.clientApi.post(`/v1/restaurant/signupOTP`, {id: registerHashId, channel: selectedContactChannel})
      .then(() => {
        setShowOTPForm(true);
        setsendOtpLoading(false);
      })
      .catch(() => {
        setOtpErrorMsg(brand.getString(ONBOARDING_GENERAL_ERROR));
        setsendOtpLoading(false);
      });
    }
  }

  const onOTPFormClose = (error:string) => {
    setShowOTPForm(false);
    if (error) {
      setLoginErrorMsg(error);
    }
  }

  const renderRadioButtons = () => {
    return (
        <div className="radio-container">
          {
            appLoading ? 
              <>
                <Skeleton height="1.25 rem" variant="rounded" />
                <Skeleton height="1.25 rem" variant="rounded" />
              </>
              : <>
                { email &&
                    <Radio
                    checked={selectedContactChannel === CONTACT_CHANNEL_OPTIONS.EMAIL}
                    label={email}
                    name="email"
                    onChange={() => setSelectedContactChannel(CONTACT_CHANNEL_OPTIONS.EMAIL)}
                  />
                }
                {
                  phoneNumber &&
                  <Radio
                    checked={selectedContactChannel === CONTACT_CHANNEL_OPTIONS.SMS}
                    label={phoneNumber}
                    name="sms"
                    onChange={() => setSelectedContactChannel(CONTACT_CHANNEL_OPTIONS.SMS)}
                  />
                }
                { selectedContactChannel === CONTACT_CHANNEL_OPTIONS.SMS && 
                  <Caption as="p" weight={400}>
                    { brand.getString(ONBOARDING_SMS_CONSENT_MSG) } 
                  </Caption>
                }
              </>
          }
          
        </div>
    );
  }

  const renderButtonsContainer = () => {
    return (
      <div className="btn-container">
        {
          otpErrorMsg &&
          <Alert variant="error">
            {otpErrorMsg}
          </Alert>
        }
        <Button 
          isFullWidth 
          disabled={!!(appLoading || appErrorMsg)}
          size="medium" 
          variant="primary"
          onClick={sendOtpCode}>
            {sendOtpLoading ? <Spinner color="white" size="small" /> : 'Send Code'}
        </Button>
        <Button 
          isFullWidth 
          disabled={!!(appLoading || appErrorMsg)}
          size="medium" 
          variant="secondary"
          onClick={() => setShowOTPForm(true)}>
          Enter Code
        </Button>
      </div>
    );
  }
  
  return (
    <LoginForm>
      <div className="Onboarding">

        {
          (appErrorMsg || loginErrorMsg) &&
          <Alert variant="error" UNSAFE_className="app-error-alert">
           {appErrorMsg || loginErrorMsg}
          </Alert>
        }
        
        <Heading as="h1" size="large" weight={700}>
          { brand.getString(ONBOARDING_TITLE) }
        </Heading>
        <Body UNSAFE_className="sub-title" as="p" size="large" weight={400}>
        { brand.getString(ONBOARDING_SUB_TITLE) }
        </Body>

        <LoginImage hidden={!PORTRAIT_VIEW} />

        <Body as="p" size="medium" weight={400}>
          Choose where you'd like to receive your verification code.
        </Body>
        { renderRadioButtons() }
        { renderButtonsContainer() }

        <OTPForm
          isOpen={showOTPForm}
          hashId={registerHashId}
          contactChannel={selectedContactChannel}
          onClose={(errorMsg:string = '') => onOTPFormClose(errorMsg)} />
      </div>
    </LoginForm>
  );
}

export default Onboarding;

function replaceAsterisksWithBullets(data:string):string {
  if (data) {
    return data.replace(/\*/gi, `\u2022`);
  }
  return '';
}