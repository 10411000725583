import { config } from '../config';
import properties from './properties';
const mixpanel = require('mixpanel-browser');

/**
 * Analytics
 */
class Analytics {

  // Public constants
  public TRACK_USER_LOGIN:string = 'Login';
  public TRACK_LOGIN_ERROR:string = 'Login Error';
  public TRACK_LOGIN_CLICKED:string = 'Login Button Clicked';
  public TRACK_USER_RETURN:string = 'Returning User';

  public TRACK_USER_LOGOUT_BEGIN:string = 'Logout - Begin';
  public TRACK_USER_LOGOUT:string = 'Logout';

  public TRACK_API_ERROR:string = 'API Error';
  
  private processEvents:boolean = false;
  private eventQueue:Array<any> = [];
  private offline:boolean = false;


  constructor() {
    this.mixpanel.init(config.mixpanelToken);
  }


  private get mixpanel():any {
    return mixpanel;
  }


  public initUser(user:any) {
    if (user && user.mixpanel_id) {
      this.setIdentity(user.mixpanel_id);
    }      
    this.processEvents = true;
    this.processQueuedEvents();
  }


  public setOffline(isOffline:boolean) {
    this.offline = isOffline;
  }


  public track(eventName:string, eventProperties:any = {}) {
    
    const doTrack = (props:any) => {
      if (this.processEvents) {
        this.mixpanel.track(eventName, props, () => {
          console.log("mixpanel track succeeded");
        });
      } else {
        this.eventQueue.push({ type: 'track', eventName, props });
      }
    };

    // Add evergreen properties
    const restaurantName = properties.getItem(properties.PROPERTY_RESTAURANT_NAME) || 'unknown';
    const restaurantId = properties.getItem(properties.PROPERTY_RESTAURANT_ID) || 'unknown';
    const data = Object.assign({}, eventProperties, {
      version: process.env.REACT_APP_VERSION,
      appVersion: 'web',
      eventUTC: (new Date()).toISOString(),
      restaurantName,
      restaurantId,
      offline: this.offline,
    });

    doTrack(data);
  }


  private setIdentity(id:string) {
    this.mixpanel.identify(id, () => {
      console.log("mixpanel identify succeeded");
    }, (error:any) => {
      console.log("mixpanel identify failed: ", error);
    });
  }

  
  private processQueuedEvents() {
    while ( this.eventQueue.length) {
      const event = this.eventQueue.shift();
      if (event.type === 'user') {
        this.setUserProperties(event.properties);
      } else if (event.type === 'once') {
        this.setUserPropertiesOnce(event.properties);
      } else if (event.type === 'track') {
        this.track(event.eventName, event.eventProperties);
      } else if (event.type === 'inc') {
        this.incrementUserProperty(event.property, event.amount);
      }
    }
  }


  private setUserProperties(properties:any) {
    if (this.processEvents) {
      this.mixpanel.people.set(properties, () => {
        console.log('mixpanel people.set succeeded');
      }, (error:any) => {
        console.log('mixpanel people.set error: ', error);
      });
    } else {
      this.eventQueue.push({ type: 'user', properties });
    }
  }


  private setUserPropertiesOnce(properties:any) {
    if (this.processEvents) {
      this.mixpanel.people.setOnce(properties, () => {
        console.log('mixpanel registerSuperPropertiesOnce succeeded');
      }, (error:any) => {
        console.log('mixpanel registerSuperPropertiesOnce error: ', error);
      });
    } else {
      this.eventQueue.push({ type: 'once', properties });
    }
  }


  private incrementUserProperty(property:any, amount:number = 1) {
    if (this.processEvents) {
      this.mixpanel.people.increment({[property]: amount}, () => {
        console.log('mixpanel incrementUserProperty succeeded');
      }, (error:any) => {
        console.log('mixpanel incrementUserProperty error: ', error);
      });
    } else {
      this.eventQueue.push({ type: 'inc', property, amount });
    }
  }
}

export default new Analytics();