import React, { useState } from 'react';
import {
    TextField,
    Heading,
    Caption,
    Modal,
    Body,
} from "@walmart-web/livingdesign-components";
import { ExclamationCircle } from "@livingdesign/icons";
import routingNumberImg from '../../../images/routingNumberImg.png';
import accountNumberImg from '../../../images/accountNumberImg.png';

import './BankingInfo.scss'

export type BankingInfoChangeEvent = {
    first_name: string,
    last_name: string,
    routing_number: string,
    account_number: string,
};

interface BankingInfoProps {
    first_name: string,
    last_name: string,
    routing_number: string,
    account_number: string,
    onChange: Function,
}

const BankingInfo: React.FunctionComponent<BankingInfoProps> = props => {
    const [reEnterAccountNumber, setReEnterAccountNumber] = useState('');
    const [routingError, setRoutingError] = useState(false);
    const [accountNumberError, setAccountNumberError] = useState(false);
    const [accountNumberMatchError, setAccountNumberMatchError] = useState(false);
    const [routingModalIsOpen, setRoutingModalIsOpen] = useState(false);
    const [accountModalIsOpen, setAccountModalIsOpen] = useState(false);


    const { first_name, last_name, routing_number, account_number, onChange } = props;

    const handleBankingInfoChange = (field: string, value: string) => {
        const current: BankingInfoChangeEvent = {
            first_name,
            last_name,
            routing_number,
            account_number,
        };
        const change: BankingInfoChangeEvent = {...current, [field]: value};
        onChange(change);
    }

    const handleRoutingNumberChange = (routingNumber: string) => {
        if (routingNumber.length === 0) {
            setRoutingError(false);
        } else if (routingNumber.match(/^[0-9]{9}$/) !== null) {
            setRoutingError(false);
        } else {
            setRoutingError(true);
        }
        handleBankingInfoChange("routing_number", routingNumber);
    }

    const handleAccountNumberChange = (accountNumber: string) => {
        if (accountNumber.length === 0) {
            setAccountNumberError(false);
        } else if (accountNumber.match(/^[0-9]+$/) !== null) {
            setAccountNumberError(false);
        } else {
            setAccountNumberError(true);
        }
        handleBankingInfoChange("account_number", accountNumber);
    }

    const handleReEnterAccountNumber = (reEnterAccountNumber: string) => {
        setReEnterAccountNumber(reEnterAccountNumber);

        if (reEnterAccountNumber === account_number) {
            setAccountNumberMatchError(false);
        } else if (reEnterAccountNumber.length === 0) {
            setAccountNumberMatchError(false);
        } else {
            setAccountNumberMatchError(true);
        }
    }

    const renderRoutingNumberModal = () => {
        return (
                <Modal
                    isOpen={routingModalIsOpen}
                    onClose={() => setRoutingModalIsOpen(false)}
                    size="large"
                    title="Banking routing number"
                >
                    <img alt="Routing number" src={routingNumberImg} />
                </Modal>
        )
    }

    const renderAccountNumberModal = () => {
        return (
                <Modal
                    isOpen={accountModalIsOpen}
                    onClose={() => setAccountModalIsOpen(false)}
                    size="large"
                    title="Banking account number"
                >
                    <img alt="Account number" src={accountNumberImg} />
                </Modal>
        )
    }

    return (
        <div className='BankingInfo'>
            <Heading
                as="h1"
                size="large"
                weight={400}
            >
                Banking information
                <Body as="p" size="small" weight={400}>
                    This will be the account we&rsquo;ll use for direct deposit.
                </Body>
            </Heading>
            <div className="textbox-wrap">
                <TextField
                    className="text-field"
                    label="First name"
                    value={first_name}
                    onChange={e => handleBankingInfoChange("first_name", e.target.value)}
                    type="text"
                />
                <TextField
                    className="text-field"
                    label="Last name"
                    value={last_name}
                    onChange={e => handleBankingInfoChange("last_name", e.target.value)}
                    type="text"
                />
            </div>
            <TextField
                className="text-field"
                error={routingError ? "Invalid Routing Number" : ""}
                label="Routing number"
                trailing={
                    <ExclamationCircle
                        onClick={() => setRoutingModalIsOpen(true)}
                        size="medium"
                    />}
                value={routing_number}
                onChange={e => handleRoutingNumberChange(e.target.value)}
                type="text"
            />
            {routingModalIsOpen && renderRoutingNumberModal()}
            <TextField
                className="text-field"
                error={accountNumberError ? "Invalid Account Number" : ""}
                label="Account number"
                value={account_number}
                trailing={
                    <ExclamationCircle
                        onClick={() => setAccountModalIsOpen(true)}
                        size="medium" />}
                onChange={e => handleAccountNumberChange(e.target.value)}
                type="text"
            />   
            {accountModalIsOpen && renderAccountNumberModal()}
            <TextField
                className="text-field"
                error={accountNumberMatchError ? "Account number mismatch" : ""}
                label="Re-enter account number"
                value={reEnterAccountNumber}
                onChange={e => handleReEnterAccountNumber(e.target.value)}
                type="text"
            />
        </div>
    );
}

export default BankingInfo;