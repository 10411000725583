import React from 'react';
import {PureComponent} from 'react';
import { MOBILE_SIZE, AppConfig } from '../../App/App';

import './Messaging.scss';

interface MessagingProps {
}

interface MessagingState {
  isMobile: boolean,
  mobileMenuHidden: boolean,
  active: boolean,
  type: string,
  message: string,
  details: string,
  callback: any,
  forceAccept: any
}

/**
 * A class that handles showing all the alert message boxes shown to the user.
 */
class Messaging extends PureComponent<MessagingProps, MessagingState> {

  constructor(props:any) {
    super(props);

    const isMobile = window.innerWidth < MOBILE_SIZE;
    this.state = {
      isMobile,
      mobileMenuHidden: isMobile,
      active: false,
      type: 'notify',
      message: '',
      details: '',
      callback: null,
      forceAccept: null
    };
    
    window.addEventListener('resize', () => {
      const isMobile = window.innerWidth < MOBILE_SIZE;

      if (isMobile !== this.state.isMobile) {
        this.setState({ isMobile, mobileMenuHidden: isMobile });
      }
    });

    AppConfig.notify = this.notify.bind(this);
    AppConfig.warn = this.warn.bind(this);
    AppConfig.closeMessage = this.close.bind(this);

    // Binding methods
    this.close = this.close.bind(this);
    this.warn = this.warn.bind(this);
    this.notify = this.notify.bind(this);
  }


  notify(msg : string, callback : any = null, details : string = '') : void{
    this.setState({
      active: true,
      message: msg,
      details: details,
      type: 'notify',
      callback: callback ? callback : null
    });
  }


  warn(msg : string, callback : any = null, details : string = '', forceAccept : boolean = true) {
    this.setState({
      active: true,
      message: msg,
      details: details,
      type: 'warn',
      callback: callback,
      forceAccept: forceAccept,
    });
  }


  error(msg : string, callback : any) {
    this.setState({
      active: true,
      message: msg,
      type: 'error',
      callback: callback ? callback : null
    });
  }


  close() {
    this.setState({ active: false });
  }


  ignore(evt : any) {
    evt.preventDefault();
    evt.stopPropagation();
  }


  render() {
    const state = this.state;
    let className = state.active ? '' : ' invisible';
    const hasCallback = state.callback;
    const force = state.forceAccept;
    const error = state.type === 'warn';

    return (
      <div onClick={ this.close } className={ "messaging " + this.state.type + className }>
        <div className="message" onClick={ this.ignore }>
          <div className={ error ? "stripe error" : "stripe success" }>
            {error ? 'Warning' : 'Success'}
          </div>
          <span className="message-text" dangerouslySetInnerHTML={ { __html: this.state.message } } />
          <span className="body" dangerouslySetInnerHTML={ { __html: this.state.details } } />
          {
            !this.state.forceAccept &&
            <div className="close" onClick={ this.close }>x</div>
          }
          <div className="button-controls">
            <div className={ "okay button secondary" + (force ? ' one-button' : '') }
                 onClick={ hasCallback ? (evt) => this.state.callback(evt) : this.close }>
              OK
            </div>
            {
              !this.state.forceAccept &&
              <div className="cancel button" onClick={ this.close }>Cancel</div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Messaging;
