
export type BrandNames = 'joyrun'|'golocal';
export type BrandOrdersURL = 'joyrunOrdersUrl'|'golocalOrdersUrl';

const configData = {
  local: {
    stripeKey: 'pk_test_51ILa5FKK4wggmwOZyvpkoJL6dvIZtBK5PoEQvCCJi85iAz1s7PODgHRVbXUVZSwY1nQi4umsxXzWadaosPQCboiV002deaDhrC',
    mixpanelToken: '2710288120cb7d2e489f26dd1a4eadc7',
    golocal: 'https://webapp.runner.wcnp.dev.walmart.com',
    joyrun: 'https://webapp.joyrun.dev.walmart.com',
    golocalOrdersUrl: 'https://orders.runner.wcnp.dev.walmart.com',
    joyrunOrdersUrl: 'https://webapp.joyrun.dev.walmart.com/orders',
  },
  dev: {
    stripeKey: 'pk_test_51ILa5FKK4wggmwOZyvpkoJL6dvIZtBK5PoEQvCCJi85iAz1s7PODgHRVbXUVZSwY1nQi4umsxXzWadaosPQCboiV002deaDhrC',
    mixpanelToken: '2710288120cb7d2e489f26dd1a4eadc7',
    golocal: 'https://webapp.runner.wcnp.dev.walmart.com',
    joyrun: 'https://webapp.joyrun.dev.walmart.com',
    golocalOrdersUrl: 'https://orders.runner.wcnp.dev.walmart.com',
    joyrunOrdersUrl: 'https://webapp.joyrun.dev.walmart.com/orders',
  },
  stage: {
    stripeKey: 'pk_test_51ILa5FKK4wggmwOZyvpkoJL6dvIZtBK5PoEQvCCJi85iAz1s7PODgHRVbXUVZSwY1nQi4umsxXzWadaosPQCboiV002deaDhrC',
    mixpanelToken: '2710288120cb7d2e489f26dd1a4eadc7',
    golocal: 'https://golocal-stage.walmart.com',
    joyrun: 'https://webapp.joyrun.stage.walmart.com',
    golocalOrdersUrl: 'https://golocal-stage.walmart.com/orders',
    joyrunOrdersUrl: 'https://webapp.joyrun.stage.walmart.com/orders',
  },
  production: {
    stripeKey: 'pk_live_51ILa5FKK4wggmwOZbX4rqBKhAdEHYzzjhZ7e1gfyHp3W2xEhN37JI5nMw9UVppU63x2u8OFXQHgCHTaGdNdImkRP00ti0tUtVp',
    mixpanelToken: 'a43f8bb11671d6380d2fe5c8a08fc15d',
    golocal: 'https://golocal.walmart.com',
    joyrun: 'https://joyrun.walmart.com',
    golocalOrdersUrl: 'https://golocal.walmart.com/orders',
    joyrunOrdersUrl: 'https://joyrun.walmart.com/orders',
  }
}

export const config = configData[window.env.REACT_APP_ENV] || {};
