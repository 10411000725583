import React from 'react';
import Page from '../../Page/Page';
import LoginImage from '../../common/LoginImage/LoginImage';
import { PORTRAIT_VIEW } from '../../../App/App';

import './LoginForm.scss';

interface LoginFormProps {
  className?:string,
}

const LoginForm:React.FunctionComponent<LoginFormProps> = props => {
  
  return (
    <Page noBG className={ "LoginForm " + (props.className ? props.className : '') }>
      <div className="login-content">

        <LoginImage hidden={PORTRAIT_VIEW} />

        <div className="right-side">
          { props.children }
        </div>
      </div>
    </Page>
  );
}

export default LoginForm;